import { useState } from "react";
import { CalendarEvent } from "@schedule-x/calendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faPerson,
  faPeopleGroup,
  faSignal,
  faStream,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../../components/utils/Modal";

interface EventModalProps {
  calendarEvent: CalendarEvent;
  onDelete: (eventId: number) => void;
  onEdit: (calendarEvent: CalendarEvent) => void;
  onClose: () => void;
}

type props = {
  calendarEvent: CalendarEvent;
};

const EventModal: React.FC<EventModalProps> = ({
  calendarEvent,
  onEdit,
  onDelete,
  onClose,
}) => {
  const start = new Date(calendarEvent.start); // Convert to Date
  const end = new Date(calendarEvent.end); // Convert to Date

  const people = calendarEvent.people;

  const formatDateRange = (start: Date, end: Date) => {
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    const startDate = start.toLocaleDateString("en-US", dateOptions); // E.g., "November 30, 2024"
    const startTime = start.toLocaleTimeString("en-US", timeOptions); // E.g., "12:00 PM"

    const endDate = end.toLocaleDateString("en-US", dateOptions); // E.g., "January 2, 2025"
    const endTime = end.toLocaleTimeString("en-US", timeOptions); // E.g., "1:00 PM"

    if (start.toDateString() === end.toDateString()) {
      // Same day: "November 30, 2024, 12:00 PM - 1:00 PM"
      return `${startDate}, ${startTime} - ${endTime}`;
    } else {
      // Different days: "November 30, 2024, 12:00 PM - January 2, 2025, 1:00 PM"
      return `${startDate}, ${startTime} - ${endDate}, ${endTime}`;
    }
  };
  // const handleSave = () => {
  //   const updatedEvent = {
  //     ...calendarEvent,
  //     title,
  //     description,
  //     start,
  //     end,
  //   };
  //   onSave(updatedEvent);
  // };

  return (
    <div className=" bg-[#eaf0f6] shadow-md rounded-lg min-h-[200px] p-4 ">
      <div className="flex justify-end space-x-4">
        <button color="btn-danger">
          <FontAwesomeIcon
            className="text-primary hover:text-primary-500"
            icon={faEdit}
            onClick={() => onEdit(calendarEvent)}
          />
        </button>

        <button color="btn-danger">
          <FontAwesomeIcon
            className="text-primary hover:text-primary-500"
            onClick={() => onDelete(Number(calendarEvent.id))}
            icon={faTrash}
          />
        </button>
        <button color="btn-danger">
          <FontAwesomeIcon
            className="text-primary hover:text-primary-500"
            onClick={onClose}
            icon={faClose}
          />
        </button>
      </div>
      {/* <h2>{isNewEvent ? "Create Event" : "Edit Event"}</h2> */}
      <div className="flex space-x-4">
        <div className="inline-block w-[14px] h-[14px] ml-2 mt-2 rounded-sm bg-primary"></div>
        <div className="inline-block">
          <h4 className="inline-block bg-[#eaf0f6] text-xl">
            {calendarEvent.title}
          </h4>
          <p className="text-gray-700">{formatDateRange(start, end)}</p>
        </div>
      </div>

      <div className="flex space-x-4 mt-8">
        <div className="inline-block w-[14px] h-[14px] ml-2 ">
          {people && people.length > 1 ? (
            <FontAwesomeIcon className="text-primary" icon={faPeopleGroup} />
          ) : (
            <FontAwesomeIcon className="text-primary" icon={faPerson} />
          )}
        </div>
        <div className="inline-block">
          <p>{people}</p>
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        <div className="inline-block w-[14px] h-[14px] ml-2 ">
          <FontAwesomeIcon className="text-primary" icon={faStream} />
        </div>
        <div className="inline-block">
          <p>{calendarEvent.description}</p>
        </div>
      </div>
      <p>{calendarEvent.location}</p>

      <div className="flex space-x-4 mt-8">
        <div className="inline-block w-[14px] h-[14px] ml-2 ">
          <FontAwesomeIcon className="text-primary" icon={faSignal} />
        </div>
        <div className="inline-block">
          <p>{calendarEvent.status === "confirmed" && <>Confirmed!!</>}</p>
        </div>
      </div>
    </div>
  );
};

export default EventModal;
