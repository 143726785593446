import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faHeart,
  faCog,
  faCommentAlt,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons"; // Importing FontAwesome icons
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../components/ui/avatar";

import CareChampionRegister from "../careChampion/Register";
import CareChampionConnect from "../careChampion/Connect";
import Modal from "../utils/Modal";

const SecondMenu: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(true); // Track login status
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false); // Modal state
  const [isConnectModalOpen, setIsConnectModalOpen] = useState<boolean>(false); // Modal state
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const location = useLocation();

  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  const handleLogout = () => {
    setIsLoggedIn(false); // Handle logout logic here
    alert("Logged out");
  };

  return (
    <div className="self-center">
      <div className="mx-auto flex items-center space-x-4">
        {/* Conditional rendering of Login/Person Icon */}
        {!isLoggedIn ? (
          <>
            <Link
              to="/admin/care-champion/login"
              className="block bg-primary p-3 border-1 border-primary rounded mt-4 md:inline-block md:mt-0 text-secondary mr-4 hover:bg-tertiary-500 hover:text-accent-100"
              aria-label="Login"
            >
              Login
            </Link>
            <button
              onClick={() => setIsModalOpen(true)}
              className="block bg-primary p-3 border-1 border-primary rounded mt-4 md:inline-block md:mt-0 text-secondary hover:bg-tertiary-500 hover:text-accent-100"
            >
              Sign Up
            </button>
            {/* <button
              onClick={() => setIsConnectModalOpen(true)}
              className="block bg-primary p-3 border-1 border-primary rounded mt-4 md:inline-block md:mt-0 text-secondary hover:bg-tertiary-500 hover:text-accent-100"
            >
              Connect
            </button> */}
          </>
        ) : (
          <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="relative inline-block w-full"
          >
            <button
              className="flex items-center bg-primary p-3 border-1 border-primary rounded mt-4 md:inline-block md:mt-0 text-secondary hover:bg-tertiary-500 hover:text-accent-100"
              aria-label="User Menu"
            >
              <Avatar>
                <AvatarImage src="https://github.com/shadcn.png" />
                <AvatarFallback>JL</AvatarFallback>
              </Avatar>
              <span> Jenny Lee</span>
            </button>

            {/* Dropdown Menu for Logged-In Users */}
            {dropdownOpen && (
              <div className="absolute left-0 w-48 bg-white border rounded shadow-lg z-10">
                <Link
                  to="/about-me"
                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-200"
                >
                  <FontAwesomeIcon icon={faUser} className="w-5 h-5 mr-2" />
                  About Me
                </Link>
                <Link
                  to="/favorites"
                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-200"
                >
                  <FontAwesomeIcon icon={faHeart} className="w-5 h-5 mr-2" />
                  Favorites
                </Link>
                <Link
                  to="/admin/care-champion/settings"
                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-200"
                >
                  <FontAwesomeIcon icon={faCog} className="w-5 h-5 mr-2" />
                  Account Settings
                </Link>
                <Link
                  to="/messages"
                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-200"
                >
                  <FontAwesomeIcon
                    icon={faCommentAlt}
                    className="w-5 h-5 mr-2"
                  />
                  Messages
                </Link>
                <button
                  onClick={handleLogout}
                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 w-full text-left"
                >
                  <FontAwesomeIcon
                    icon={faSignOutAlt}
                    className="w-5 h-5 mr-2"
                  />
                  Logout
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Modals */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <CareChampionRegister />
      </Modal>
      <Modal
        isOpen={isConnectModalOpen}
        onClose={() => setIsConnectModalOpen(false)}
      >
        <CareChampionConnect />
      </Modal>
    </div>
  );
};

export default SecondMenu;
