import BlogPost from "./BlogPost";

const BlogControl: React.FC = () => {
  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold mb-4">Blog Post Editor</h1>
      <input
        type="text"
        placeholder="Search blog post by id"
        className="w-1/4 p-2 mb-4"
      />
      <BlogPost />
    </div>
  );
};

export default BlogControl;
