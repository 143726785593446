import { useForm, SubmitHandler } from "react-hook-form";
import axiosInstance from "../../components/api/axiosInstance"; // Adjust the import path as needed
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

enum RegisterFor {
  MYSELF = "myself",
  SOMEONE_ELSE = "someone else",
}

enum TypeOfCare {
  MEMORY_CARE = "Memory Care",
  INDEPENDENT_LIVING = "Independent Living",
  ASSISTED_LIVING = "Assisted Living",
}

enum Transition {
  IMMEDIATELY = "Immediately",
  WITHIN_3_MONTHS = "Within 3 months",
  THREE_TO_SIX_MONTHS = "3 to 6 months",
  MORE_THAN_SIX_MONTHS = "More than 6 months",
}

type FormData = {
  firstname: string;
  lastname: string;
  registerFor: RegisterFor;
  email: string;
  telephone: string;
  typeOfCare: TypeOfCare;
  transition: Transition;
};

const Connect: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      const response = await axiosInstance.post("/Authenticate/register", data);
      toast.success("Registration successful!");
      console.log("Registration successful:", response.data);
    } catch (error) {
      toast.error("Registration failed. Please try again.");
      console.error("Registration failed:", error);
    }
  };

  return (
    <div className="flex items-center justify-center bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-center text-primary">
          Connect With An Advisor
        </h2>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          {/* First Name */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              First Name
            </label>
            <input
              type="text"
              {...register("firstname", { required: "First name is required" })}
              className="input-field"
            />
            {errors.firstname && (
              <p className="text-red-500 text-sm">{errors.firstname.message}</p>
            )}
          </div>

          {/* Last Name */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Last Name
            </label>
            <input
              type="text"
              {...register("lastname", { required: "Last name is required" })}
              className="input-field"
            />
            {errors.lastname && (
              <p className="text-red-500 text-sm">{errors.lastname.message}</p>
            )}
          </div>

          {/* Register For */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Registering For
            </label>
            <select
              {...register("registerFor", {
                required: "This field is required",
              })}
              className="input-field"
            >
              {Object.values(RegisterFor).map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {errors.registerFor && (
              <p className="text-red-500 text-sm">
                {errors.registerFor.message}
              </p>
            )}
          </div>

          {/* Email */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              {...register("email", { required: "Email is required" })}
              className="input-field"
            />
            {errors.email && (
              <p className="text-red-500 text-sm">{errors.email.message}</p>
            )}
          </div>

          {/* Telephone */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Telephone
            </label>
            <input
              type="tel"
              {...register("telephone", {
                required: "Telephone is required",
              })}
              className="input-field"
            />
            {errors.telephone && (
              <p className="text-red-500 text-sm">{errors.telephone.message}</p>
            )}
          </div>

          {/* Type of Care */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Type of Care
            </label>
            <select
              {...register("typeOfCare", {
                required: "This field is required",
              })}
              className="input-field"
            >
              {Object.values(TypeOfCare).map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {errors.typeOfCare && (
              <p className="text-red-500 text-sm">
                {errors.typeOfCare.message}
              </p>
            )}
          </div>

          {/* Transition */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Transition Timeline
            </label>
            <select
              {...register("transition", {
                required: "This field is required",
              })}
              className="input-field"
            >
              {Object.values(Transition).map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {errors.transition && (
              <p className="text-red-500 text-sm">
                {errors.transition.message}
              </p>
            )}
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full py-2 px-4 bg-primary text-white rounded-md"
          >
            Connect
          </button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};
export default Connect;
