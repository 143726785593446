import React from "react";
import bannerImg from "../../assets/banners/Resource page.png";
import BannerHeading from "../../components/utils/BannerHeading";

const Resources: React.FC = () => {
  return (
    <>
      <BannerHeading
        title="Welcome to the Bluejay Resource Center"
        width="2/3"
      />
      <img className="w-full" src={bannerImg} alt="banner image" />
      <div className="container mx-auto px-4 py-10">
        <h2 className="text-xl  mb-4">
          At Bluejay, we understand that navigating senior living options and
          managing health challenges such as Alzheimer’s and dementia can be
          complex and overwhelming. That’s why we’ve curated a comprehensive
          list of resources to guide you through every aspect of senior
          living—from understanding health conditions to legal and financial
          planning. Whether you’re seeking information for yourself or a loved
          one, our Resource Center is here to empower you with the knowledge and
          tools you need for this journey.
        </h2>
        <p className="text-lg mb-4">
          Explore our carefully selected resources below to find support,
          answers, and community services designed to enhance the quality of
          life for seniors.
        </p>

        <h3 className="text-xl font-semibold mt-6 mb-2">
          Health and Alzheimer’s/Dementia Resources
        </h3>
        <ul className="list-none pl-5 list-inside mb-4">
          <li>
            <strong>Alzheimer’s Association:</strong> Comprehensive information
            on Alzheimer’s disease, caregiving tips, and local support services.{" "}
            <a
              href="https://www.alz.org/"
              className="block text-blue-500 mb-2 underline"
            >
              Visit Alzheimer’s Association
            </a>
          </li>
          <li>
            <strong>National Institute on Aging (NIA):</strong> Detailed guides
            on aging, Alzheimer’s disease, and related dementias with treatment
            options and research updates.{" "}
            <a
              href="https://www.nia.nih.gov/"
              className="block text-blue-500 mb-2 underline"
            >
              Visit National Institute on Aging
            </a>
          </li>
          <li>
            <strong>Alzheimer’s Foundation of America:</strong> Resources and
            support systems for Alzheimer’s patients and their families,
            including community events and educational materials.{" "}
            <a
              href="https://www.alzfdn.org/"
              className="block text-blue-500 mb-2 underline"
            >
              Visit Alzheimer’s Foundation of America
            </a>
          </li>
        </ul>

        <h3 className="text-xl font-semibold mt-6 mb-2">
          Legal and Financial Assistance
        </h3>
        <ul className="list-none pl-5 list-inside mb-4">
          <li>
            <strong>Elder Law Answers:</strong> Legal resources specifically for
            aging-related issues, including estate planning and rights for those
            with dementia.{" "}
            <a
              href="https://www.elderlawanswers.com/"
              className="block text-blue-500 mb-2 underline"
            >
              Visit Elder Law Answers
            </a>
          </li>
          <li>
            <strong>Benefits CheckUp:</strong> A tool provided by the National
            Council on Aging to help seniors find and enroll in financial
            assistance programs.{" "}
            <a
              href="https://www.benefitscheckup.org/"
              className="block text-blue-500 mb-2 underline"
            >
              Visit Benefits CheckUp
            </a>
          </li>
        </ul>

        <h3 className="text-xl font-semibold mt-6 mb-2">
          General Senior Living
        </h3>
        <ul className="list-none pl-5 list-inside mb-4">
          <li>
            <strong>Administration on Aging (AoA):</strong> Services and support
            for older individuals living independently.{" "}
            <a
              href="https://www.acl.gov/oa"
              className="block text-blue-500 mb-2 underline"
            >
              Visit Administration on Aging
            </a>
          </li>
          <li>
            <strong>USA.gov for Seniors:</strong> Government resources for
            seniors on health, financial planning, and social security.{" "}
            <a
              href="https://www.usa.gov/seniors"
              className="block text-blue-500 mb-2 underline"
            >
              Visit USA.gov for Seniors
            </a>
          </li>
        </ul>

        <h3 className="text-xl font-semibold mt-6 mb-2">
          Medicare and Health Planning
        </h3>
        <ul className="list-none pl-5 list-inside mb-4">
          <li>
            <strong>Medicare.gov:</strong> Official government site for Medicare
            information, including coverage, drug plans, and finding providers.{" "}
            <a
              href="https://www.medicare.gov/"
              className="block text-blue-500 mb-2 underline"
            >
              Visit Medicare
            </a>
          </li>
          <li>
            <strong>Eldercare Locator:</strong> A public service connecting
            older adults and their families with services in their community.{" "}
            <a
              href="https://eldercare.acl.gov/"
              className="block text-blue-500 mb-2 underline"
            >
              Visit Eldercare Locator
            </a>
          </li>
        </ul>

        <h3 className="text-xl font-semibold mt-6 mb-2">
          Community Engagement and Education
        </h3>
        <ul className="list-none pl-5 list-inside mb-4">
          <li>
            <strong>AARP:</strong> Tools and information on retirement,
            Medicare, and activities for those over 50.{" "}
            <a
              href="https://www.aarp.org/"
              className="block text-blue-500 mb-2 underline"
            >
              Visit AARP
            </a>
          </li>
          <li>
            <strong>SeniorNet:</strong> Educational resources on technology for
            seniors to improve skills and stay connected.{" "}
            <a
              href="http://www.seniornet.org/"
              className="block text-blue-500 mb-2 underline"
            >
              Visit SeniorNet
            </a>
          </li>
        </ul>

        <p className="font-bold">
          Our goal at Bluejay is to provide a pathway through the maze of senior
          care and living options, helping you make informed decisions with
          confidence. If you have any questions or need further assistance,
          don’t hesitate to reach out.
        </p>
      </div>
    </>
  );
};

export default Resources;
