import React, { useState, useEffect } from "react";
import carousel1 from "../../assets/carousel-1.png";
import carousel2 from "../../assets/carousel-2.png";
import carousel3 from "../../assets/carousel-3.png";

const images = [carousel1, carousel2, carousel3];

const descriptions = [
  "To keep your balance, you must keep moving.",
  "click away.",
  "- Jimmy Buffet",
];

const headings = [
  "Together we get you there.",
  "New beginnings, just",
  "Wrinkles will only go where the smiles have been.",
];

const VerticalCarousel: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Auto-slide every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change slide every 5 seconds
    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  const goToImage = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div className="flex flex-col items-center h-auto  space-y-4">
      <div className="relative w-full overflow-hidden shadow-lg">
        {/* <div className="modal-overlay fixed top-0 left-0  z-50"> */}
        <img
          src={images[currentIndex]}
          alt={`Slide ${currentIndex}`}
          className="w-full h-full object-cover transition-transform duration-500 ease-in-out"
        />
        {/* </div> */}
        {/* Paragraph Box */}
        <div className="absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/4 bg-white bg-opacity-75 p-4 rounded-lg shadow-lg w-1/4 text-center">
          <h2 className="text-xl font-medium text-gray-500">
            {headings[currentIndex]}
          </h2>
          <p className="text-lg font-medium text-gray-800">
            {descriptions[currentIndex]}
          </p>
        </div>

        {/* Vertical Progress Bar Indicators */}
        <div className="absolute top-1/2 right-4 transform -translate-y-1/2 flex flex-col space-y-2">
          {images.map((_, index) => (
            <div key={index} className="w-2 h-12 bg-primary rounded-lg">
              <div
                className={`h-full bg-secondary transition-all duration-500 ease-linear ${
                  index === currentIndex ? "animate-progress" : ""
                }`}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VerticalCarousel;
