import React, { useEffect, useState } from "react";

type Task = {
  name: string;
  type:
    | "follow_up"
    | "call"
    | "listing_appointment"
    | "email"
    | "text"
    | "tour"
    | "welfare_check";
  dueDate: string;
  dueTime: string;
};

interface CreateTaskProps {
  onCreateTask: (task: Task) => void;
  initialData?: Task | null;
}

const CreateTask: React.FC<CreateTaskProps> = ({
  onCreateTask,
  initialData = null,
}) => {
  const [taskName, setTaskName] = useState<string>("");
  const [taskCategory, setTaskCategory] = useState<string>("follow_up");
  const [dueDate, setDueDate] = useState<string>(
    new Date().toISOString().split("T")[0]
  );
  const [dueTime, setDueTime] = useState<string>("12:00");

  useEffect(() => {
    if (initialData) {
      setTaskName(initialData.name);
      setTaskCategory(initialData.type);
      setDueDate(initialData.dueDate);
      setDueTime(initialData.dueTime);
    }
  }, [initialData]);

  const handleCreateTask = (e: React.FormEvent) => {
    e.preventDefault();
    const task: Task = {
      name: taskName,
      type: taskCategory as Task["type"],
      dueDate: dueDate,
      dueTime: dueTime,
    };
    onCreateTask(task);
    document.body.style.overflow = "";
  };

  return (
    <>
      <h3 className="font-bold mb-2">Create Task</h3>
      <form className="space-y-2" onSubmit={handleCreateTask}>
        <input
          value={taskName}
          onChange={(e) => setTaskName(e.target.value)}
          className=" input-field"
          type="text"
          placeholder="Task Name"
        />
        <select
          value={taskCategory}
          onChange={(e) => setTaskCategory(e.target.value)}
          className="input-field"
        >
          <option value="follow_up">Follow Up</option>
          <option value="call">Call</option>
          <option value="listing_appointment">Listing Appointment</option>
          <option value="email">Email</option>
          <option value="text">Text</option>
          <option value="tour">Tour</option>
          <option value="welfare_check">Welfare Check</option>
        </select>
        <div className="flex space-x-2">
          <input
            onChange={(e) => setDueDate(e.target.value)}
            type="date"
            className="input-field"
            value={dueDate}
          />
          <input
            onChange={(e) => setDueTime(e.target.value)}
            type="time"
            className="input-field"
            value={dueTime}
          />
        </div>
        <button type="submit" className="btn-primary  w-full">
          {initialData ? "Update Task" : "Create Task"}
        </button>
      </form>
    </>
  );
};

export default CreateTask;
