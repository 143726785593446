import { useEffect, useState } from "react";
import { Vendor, columns } from "./ClientTransitionSupport/Columns";
import { DataTable } from "./ClientTransitionSupport/Data-Table";

export const vendors: Vendor[] = [
  // ...
];

export const fetchData = async () => {
  // Fetch data from your API here.
  alert("Fetching data from your API here.");
  return vendors;
};

async function getData(): Promise<Vendor[]> {
  // Fetch data from your API here.
  return [
    {
      id: "1",
      vendor: "Moving Company",
      contact: "Sherry Daily",
      title: "Owner",
      email: "sherry.daily@example.com",
      telephone: "555-123-4567",
      additional:
        "Last email: An hour ago, Last message: 6 hours ago, Created: An hour ago",
    },
    {
      id: "2",
      vendor: "Attorney",
      contact: "Geoff Goodman",
      title: "Owner",
      email: "geoff.goodman@example.com",
      telephone: "555-876-5432",
      additional:
        "Last email: An hour ago, Last message: 6 hours ago, Created: An hour ago",
    },
    {
      id: "3",
      vendor: "Handyman",
      contact: "Peter May",
      title: "Owner",
      email: "peter.may@example.com",
      telephone: "555-987-6543",
      additional:
        "Last email: A day ago, Last message: 2 Days, Created: A day ago",
    },
    {
      id: "4",
      vendor: "Home Care Provider",
      contact: "Raul Cruz",
      title: "Owner",
      email: "raul.cruz@example.com",
      telephone: "555-432-1597",
      additional:
        "Last email: A day ago, Last message: 2 Days, Created: A day ago",
    },
    {
      id: "5",
      vendor: "Garage Sale",
      contact: "Owner",
      title: "Owner",
      email: "",
      telephone: "",
      additional: "Last Telephone: 6 months ago",
    },
    {
      id: "6",
      vendor: "Estate Sale",
      contact: "",
      title: "Owner",
      email: "",
      telephone: "",
      additional: "",
    },
    {
      id: "7",
      vendor: "Cleaning Crews",
      contact: "",
      title: "Owner",
      email: "",
      telephone: "",
      additional: "",
    },
    {
      id: "8",
      vendor: "Plumber",
      contact: "Sarah Miller",
      title: "Manager",
      email: "sarah.miller@example.com",
      telephone: "555-123-4567",
      additional:
        "Last email: 3 days ago, Last message: 2 weeks ago, Created: 1 month ago",
    },

    {
      id: "9",
      vendor: "Electrician",
      contact: "David Lee",
      title: "Technician",
      email: "david.lee@example.com",
      telephone: "555-789-0123",
      additional:
        "Last email: 2 days ago, Last message: 1 week ago, Created: 2 weeks ago",
    },

    {
      id: "10",
      vendor: "Roofing Contractor",
      contact: "Emily Parker",
      title: "Owner",
      email: "emily.parker@example.com",
      telephone: "555-567-8901",
      additional:
        "Last email: 4 days ago, Last message: 3 days ago, Created: 1 week ago",
    },

    {
      id: "11",
      vendor: "HVAC Technician",
      contact: "Michael Wilson",
      title: "Service Manager",
      email: "michael.wilson@example.com",
      telephone: "555-234-5678",
      additional:
        "Last email: 5 days ago, Last message: 2 days ago, Created: 2 days ago",
    },

    {
      id: "12",
      vendor: "Painter",
      contact: "Sophia Young",
      title: "Owner",
      email: "sophia.young@example.com",
      telephone: "555-345-6789",
      additional:
        "Last email: 1 week ago, Last message: 4 days ago, Created: 3 weeks ago",
    },

    {
      id: "13",
      vendor: "Carpenter",
      contact: "Daniel Taylor",
      title: "Foreman",
      email: "daniel.taylor@example.com",
      telephone: "555-456-7890",
      additional:
        "Last email: 2 weeks ago, Last message: 1 month ago, Created: 2 months ago",
    },

    {
      id: "14",
      vendor: "Landscaper",
      contact: "Olivia Thompson",
      title: "Owner",
      email: "olivia.thompson@example.com",
      telephone: "555-567-8902",
      additional:
        "Last email: 3 weeks ago, Last message: 2 weeks ago, Created: 1 month ago",
    },

    {
      id: "15",
      vendor: "Pool Contractor",
      contact: "William Anderson",
      title: "Project Manager",
      email: "william.anderson@example.com",
      telephone: "555-678-9012",
      additional:
        "Last email: 4 weeks ago, Last message: 3 weeks ago, Created: 2 months ago",
    },

    {
      id: "16",
      vendor: "General Contractor",
      contact: "Ava Martinez",
      title: "Owner",
      email: "ava.martinez@example.com",
      telephone: "555-789-0124",
      additional:
        "Last email: 1 month ago, Last message: 4 weeks ago, Created: 3 months ago",
    },

    {
      id: "17",
      vendor: "Plasterer",
      contact: "Ethan Davis",
      title: "Technician",
      email: "ethan.davis@example.com",
      telephone: "555-890-1234",
      additional:
        "Last email: 2 months ago, Last message: 1 month ago, Created: 4 months ago",
    },
  ];
}

const Page = () => {
  const [data, setData] = useState<Vendor[] | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch data when the component mounts
    getData()
      .then((fetchedData) => {
        setData(fetchedData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <p>Loading...</p>; // Placeholder for loading state
  }

  if (!data) {
    return <p>No data available.</p>; // Placeholder for no data state
  }

  return (
    <div className="container mx-auto py-10">
      <DataTable columns={columns} data={data} />
    </div>
  );
};

export default Page;
