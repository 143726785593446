import React, { useState } from "react";
import { CalendarEvent } from "@schedule-x/calendar";
import TextInput from "../TextInput";

interface CreateEventProps {
  onSave: (updatedEvent: CalendarEvent) => void;
  setShowCreateEvent: (showCreateEvent: boolean) => void;
  // onCancel: () => void;
}

const CreateEvent: React.FC<CreateEventProps> = ({
  onSave,
  setShowCreateEvent,
  // onCancel,
}) => {
  const [newEvent, setNewEvent] = useState<CalendarEvent>({
    id: "",
    title: "",
    description: "",
    start: "",
    end: "",
    location: "",
    people: [],
    status: "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setNewEvent((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = () => {
    // Format the people field as an array

    onSave(newEvent);
    setShowCreateEvent(false);
    document.body.style.overflow = "";
  };

  return (
    <div className="p-6 bg-white rounded-md shadow-lg max-w-md mx-auto">
      <h2 className="text-xl font-semibold mb-4">Create Event</h2>

      <div>
        {["title", "description", "start", "end", "people", "location"].map(
          (key) => {
            const value = newEvent[key];
            return (
              <TextInput
                key={key}
                label={key.charAt(0).toUpperCase() + key.slice(1)}
                placeholder={
                  key === "start" || key === "end" ? "YYYY-MM-DD HH:mm" : ""
                }
                name={key}
                value={value}
                onChange={handleInputChange}
                className="w-full mt-1 p-2 border rounded-md"
                type={
                  key === "start" || key === "end" ? "datetime-local" : "text"
                }
              />
            );
          }
        )}
      </div>

      <div className="flex justify-end gap-4 mt-4">
        <button
          onClick={handleSave}
          className="px-4 py-2 bg-primary text-white rounded-md"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default CreateEvent;
