import ourStoryImg from "../../assets/ourstoryImg.png";
import BannerHeading from "../../components/utils/BannerHeading";

const OurStory: React.FC = () => {
  return (
    <>
      <div className="w-full overflow-hidden">
        <BannerHeading title="WHAT WE OFFER YOU" width="1/3" />
      </div>
      <div className="container flex flex-col lg:flex-row  mt-7">
        <div className="container w-full md:w-1/2 mx-auto p-4 md:p-10 ">
          <h2
            className="text-3xl font-bold  blog-title  
  first-letter:text-8xl first-letter:font-bold first-letter:text-primary
  mb-8"
          >
            Find the Right Senior Community Easily and for FREE.
          </h2>
          <p>
            BlueJayCares® helps you connect with senior living communities and
            other qualified professionals for free.
          </p>
          <p>We don't charge a fee to you or the senior communities.</p>
          <br />
          <h3>How We Help, Free of Charge</h3>
          <p>Our services are free ...</p>
          <p>Our goal...</p>
          <br />
        </div>
        <div className="w-1/2 md:w-1/2 mx-auto">
          <img src={ourStoryImg} alt="walter img" className="w-full" />
        </div>
      </div>
    </>
  );
};

export default OurStory;
