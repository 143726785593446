import { useEffect, useState } from "react";
import bannerImg from "../../assets/banners/blog.png";
import axiosInstance from "../api/axiosInstance";
import BannerHeading from "../utils/BannerHeading";
import BannerImg from "../utils/BannerImg";

interface BlogComponentProps {
  blogId: string;
}

type BlogData = {
  id: string;
  title: string;
  body: string;
  publishedOn: string;
  author: string;
};

const BlogComponent: React.FC<BlogComponentProps> = ({ blogId }) => {
  const [blogData, setBlogData] = useState<BlogData>({
    id: "",
    title: "",
    body: "",
    publishedOn: "",
    author: "",
  });

  useEffect(() => {
    axiosInstance
      .get(`/Blogs/${blogId}`)
      .then((response) => {
        setBlogData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="mb-10">
      <div className="w-full overflow-hidden my-4">
        <BannerHeading title="Welcome to the BlueJay Blog" width="2/3" />
        <div className="w-full overflow-hidden ">
          <BannerImg imgUrl={bannerImg} />
        </div>
      </div>
      <div className="container">
        <h1
          className="blog-title font-bold text-3xl first-line:uppercase tracking-widest
  first-letter:text-7xl first-letter:font-bold first-letter:text-primary
  first-letter:mr-3 first-letter:float-left"
        >
          {blogData.title}
        </h1>

        <div
          className="prose max-w-none"
          dangerouslySetInnerHTML={{ __html: blogData.body }}
        />
      </div>
    </div>
  );
};

export default BlogComponent;
