import React from "react";
import SeniorAdvisorCard from "../../../advisor/SeniorAdvisorCard";
import { Advisor } from "../../../../types/advisor";
interface AdvisorProfileProps {
  advisor?: Advisor;
}

const AdvisorProfile: React.FC<AdvisorProfileProps> = ({ advisor }) => {
  return (
    <div className="flex flex-col md:flex-row p-4  bg-white shadow-md rounded-lg">
      <SeniorAdvisorCard advisor={advisor} />
    </div>
  );
};

export default AdvisorProfile;
