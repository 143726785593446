import { useUserContext } from "../../context/UserContext";

const RoleSwitcher: React.FC = () => {
  const { userRole, setUserRole } = useUserContext();

  return (
    <>
      <div className="w-2/3 ml-3 bg-gray-200 p-4 rounded-lg shadow-md">
        <h2 className="text-lg font-semibold mb-2">Current Role: {userRole}</h2>
        <select
          value={userRole}
          onChange={(e) => setUserRole(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
        >
          <option value="super-admin">Super Admin</option>

          <option value="senior-advisor">Senior Advisor</option>
          <option value="care-champion">Care Champion</option>
          <option value="community">Community</option>
        </select>
      </div>
    </>
  );
};

export default RoleSwitcher;
