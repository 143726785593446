import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import { User } from "../types/types";
import BlueJayData from "./BlueJay_Imported.json"; // Import the JSON file

// Define the context type
interface UserContextType {
  userRole: string;
  setUserRole: (role: string) => void;
  user: User;
  likedCommunities?: TempCommunity[]; // Assuming community IDs are numbers
  toggleLikeCommunity?: (communityId: number) => void;
}

interface TempCommunity {
  Id: number;
  Name: string;
  Address1: string;
  City: string;
  State: string;
  Zip: number;
  Phone: string;
  StateLicense: number;
  likedCommunities: boolean;
  FIELD10: string;
  FIELD11: string;
  Website: string;
}
// Create the context with a default value
const UserContext = createContext<UserContextType | undefined>({
  userRole: "super-admin",
  setUserRole: () => {},
  user: { name: "David Clark", id: 1 },
});

// Create a provider component
export const UserProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [userRole, setUserRole] = useState<string>("super-admin"); // Default role
  const [user] = useState<User>({ name: "David Clark", id: 1 }); // Default user
  const [likedCommunities, setLikedCommunities] = useState<TempCommunity[]>([]);

  // // Fetch liked communities when the user logs in
  // useEffect(() => {
  //   const fetchLikedCommunities = async () => {
  //     if (user) {
  //       // Ensure user is set
  //       try {
  //         const response = await fetch(
  //           `/api/liked-communities?userId=${user.id}`
  //         );
  //         const data = await response.json();
  //         setLikedCommunities(new Set(data.likedCommunities));
  //       } catch (error) {
  //         console.error("Error fetching liked communities:", error);
  //       }
  //     }
  //   };

  //   fetchLikedCommunities();
  // }, [user]); // Run this when user changes

  useEffect(() => {
    const initializeLikedCommunities = () => {
      if (user) {
        // Ensure user is set
        try {
          // Assuming BlueJayData has a likedCommunities key with an array of IDs
          const likedCommunitiesFromData = BlueJayData.filter(
            (obj) => obj.likedCommunities === true
          ) as TempCommunity[];
          console.log("likedCommunitiesFromData", likedCommunitiesFromData);
          setLikedCommunities(likedCommunitiesFromData);
        } catch (error) {
          console.error("Error initializing liked communities:", error);
        }
      }
    };

    initializeLikedCommunities();
  }, [user]);

  // Function to add/remove a community from liked communities
  const toggleLikeCommunity = (communityId: number) => {
    if (communityId === undefined) {
      return;
    }

    setLikedCommunities((prev) => {
      const updated = [...prev];
      const communityIndex = updated.findIndex(
        (community) => community.Id === communityId
      );

      if (communityIndex !== -1) {
        updated.splice(communityIndex, 1);
      } else {
        updated.push({
          Id: communityId,
          Name: "Test liked community", // Add other properties as needed
          Address1: "",
          City: "",
          State: "",
          Zip: 0,
          Phone: "",
          StateLicense: 0,
          likedCommunities: true,
          FIELD10: "",
          FIELD11: "",
          Website: "",
        });
      }
      return updated;
    });
  };

  return (
    <UserContext.Provider
      value={{
        userRole,
        setUserRole,
        user,
        likedCommunities,
        toggleLikeCommunity,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the UserContext
export const useUserContext = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};
