interface NameProps {
  fname?: string;
  lname?: string;
}

const Name: React.FC<NameProps> = ({ fname, lname }) => (
  <h1 className="text-2xl font-bold mb-2">
    {fname} {lname}
  </h1>
);

export default Name;
