import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link } from "react-router-dom";

interface BlogPost {
  id: number;
  title: string;
  body: string;
  publishedOn: string;
  visible: boolean;
  author: string;
  imgUrl?: string;
}

interface BlogGridProps {
  posts: BlogPost[];
}

const BlogGrid: React.FC<BlogGridProps> = ({ posts }) => {
  const [visiblePosts, setVisiblePosts] = useState(6); // Start by displaying 6 posts

  const handleLoadMore = () => {
    setVisiblePosts((prev) => prev + 6); // Load 6 more posts each time
  };

  return (
    <section className="px-6 py-10">
      <h2 className="text-3xl font-bold text-gray-800 mb-4">Latest Stories</h2>
      <p className="text-gray-600 mb-8">Browse and read the latest stuff</p>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {posts
          .filter((post) => post.visible)
          .slice(0, visiblePosts) // Show only the number of posts in visiblePosts
          .map((post) => (
            <div
              key={post.id}
              className="bg-white shadow-md rounded-lg overflow-hidden"
            >
              <img
                // src={post.imgUrl}
                src={`https://picsum.photos/id/${post.id * 10}/500/300`}
                alt={post.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-4 w-3/4 relative top-[-28px] bg-white">
                <h3 className="text-xl font-bold text-gray-800">
                  {post.title}
                </h3>
                <div className="flex flex-col items-start mt-4 text-gray-500 text-sm">
                  <span>{post.publishedOn}</span>
                  <span>{post.author}</span>
                  {/* <span className="mx-2">|</span> */}
                </div>

                <div
                  className="text-gray-600 mt-2 blog-post-divider"
                  dangerouslySetInnerHTML={{
                    __html: post.body.substring(0, 40),
                  }}
                />
                <Link
                  to={`/blog-pages/${post.id}`}
                  className="mt-4 text-primary hover:underline font-semibold "
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Read More
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className="text-primary ml-2 hover:underline"
                  />
                </Link>
              </div>
            </div>
          ))}
      </div>
      {visiblePosts < posts.filter((post) => post.visible).length && (
        <div className="flex justify-center mt-8">
          <button
            onClick={handleLoadMore}
            className="px-4 py-2 bg-primary text-white font-semibold rounded-md hover:bg-primary-200"
          >
            More Posts
          </button>
        </div>
      )}
    </section>
  );
};

export default BlogGrid;
