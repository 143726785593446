// SingleAdvisor.js

interface SingleAdvisorProps {
  advisor?: {
    id: number;
    fname: string;
    lname: string;
    dreNo: number;
    email: string;
    mobile: string;
    office: string;
    officeName: string;
    address: string;
    city: string;
    state: string;
    zip: number;
    totalNo: number;
    totalSale: number;
    projTitleTotalFee: number;
    lsNo: number | null;
    lsSale: number;
    projTitleLsFee: number;
    bsNo: number | null;
    bsSale: number;
    projTitleBsFee: number;
    ddNo: number;
    ddSale: number;
    projTitleDdFee: number | null;
  };
  handlePrevious: () => void;
  handleNext: () => void;
}

const SingleAdvisor: React.FC<SingleAdvisorProps> = ({
  advisor,
  handlePrevious,
  handleNext,
}) => {
  return (
    <div className="flex flex-col justify-center mb-6">
      {advisor && (
        <>
          <img
            src={`https://via.placeholder.com/150?text=${advisor.fname}+${advisor.lname}`}
            alt={advisor.fname}
            className="rounded-full w-32 h-32 object-contain mb-3"
          />
          <p className="font-semibold ">
            {advisor.fname} {advisor.lname} | SRES Senior Advisor
          </p>
          <p className="text-sm text-gray-600">Dre #: {advisor.dreNo}</p>

          {/* Buttons to navigate advisors */}
          <div className="flex space-x-4 mt-4">
            <button
              onClick={handlePrevious}
              className="px-4 py-2 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400"
            >
              Previous
            </button>
            <button
              onClick={handleNext}
              className="px-4 py-2 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400"
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default SingleAdvisor;
