// GridAdvisors.js
import React from "react";
import { Link } from "react-router-dom";
import { Advisor } from "../../types/advisor";

// Define the prop types for GridAdvisors
interface GridAdvisorsProps {
  advisors: Advisor[];
  showMore: boolean;
  toggleShowMore: () => void;
}

const MultipleAdvisors: React.FC<GridAdvisorsProps> = ({
  advisors,
  showMore,
  toggleShowMore,
}) => {
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {advisors.map((advisor) => (
          <Link
            to={`/senior-advisor/${advisor.id}`}
            key={advisor.id}
            className="bg-gray-100 p-4 rounded-lg shadow-md flex flex-col items-center hover:bg-gray-200 transition duration-300"
          >
            <img
              src={`https://via.placeholder.com/150?text=${advisor.fname}+${advisor.lname}`}
              alt={advisor.fname}
              className="rounded-full w-54 h-54 object-cover mb-3"
            />
            <p className="font-semibold text-center">
              {advisor.fname} {advisor.lname} | SRES Senior Advisor
            </p>
            <p className="text-sm text-center text-gray-600">
              Dre #: {advisor.dreNo}
            </p>
          </Link>
        ))}
      </div>

      {/* More Advisors Button */}
      <div className="text-right my-6">
        <button
          onClick={toggleShowMore}
          className="px-6 py-3 bg-primary text-white rounded-lg font-semibold hover:bg-secondary transition duration-300"
        >
          {showMore ? "Show Less" : "More Advisors"}
        </button>
      </div>
    </>
  );
};

export default MultipleAdvisors;
