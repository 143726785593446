import bannerImg from "../../assets/banners/Senior Living Guide.png";
import img2 from "../../assets/seniorLivingGuideImg.jpg";
import BannerHeading from "../../components/utils/BannerHeading";
import BannerImg from "../../components/utils/BannerImg";
import BottomBorder from "../../components/utils/BottomBorder";

const SeniorLivingGuide: React.FC = () => {
  return (
    <>
      <BannerHeading title="Senior Living Guide" width="1/3" />

      <BannerImg imgUrl={bannerImg} />
      <div>
        <div className="container flex flex-col md:flex-row mb-10">
          <div className="w-full md:w-4/6 md:px-10">
            <h2 className="text-xxl font-bold my-2 text-left pb-4 w-full">
              Navigating Your Journey with BlueJay®
            </h2>
            <p>
              At BlueJay®, we understand that transitioning into senior living
              is a significant decision that requires thoughtful planning,
              research, and support. Whether exploring options for yourself or
              assisting a loved one, our Senior Living Guide provides the tools
              and information you need to make confident, informed choices.
              While we offer guidance, BlueJay® does not endorse any specific
              communities or services mentioned here. Our advice is unbiased and
              aimed at supporting you in your senior living journey.
            </p>
          </div>
          <div className="mx-auto w-2/3 md:w-2/6 my-5">
            <img src={img2} alt="senior living guide" className="mb-7" />
          </div>
        </div>
        <BottomBorder width="4/6" right={true} />
      </div>

      <div className="container mx-auto p-8">
        <hr />
        <section>
          <h2 className="text-2xl font-semibold my-4">
            1. Understanding Your Senior Living Options
          </h2>
          <p className="mb-4">
            The first step is understanding the different types of senior living
            communities available and determining which best suits your needs or
            those of your loved one:
          </p>
          <ul className="list-none pl-5 mb-8">
            <li className="mb-2">
              <strong>Independent Living:</strong> This is for active seniors
              seeking a community environment with minimal assistance but with
              access to social activities, amenities, and recreational
              opportunities.
            </li>
            <li className="mb-2">
              <strong>Assisted Living:</strong> Ideal for those who need help
              with daily activities (e.g., bathing, dressing, medication
              management) while still enjoying an independent lifestyle in a
              supportive community setting.
            </li>
            <li className="mb-2">
              <strong>Memory Care:</strong> Designed for individuals with
              Alzheimer’s or other forms of dementia, providing a safe and
              structured environment with trained professionals.
            </li>
            <li className="mb-2">
              <strong>Skilled Nursing Communities:</strong> Offer 24/7 medical
              care and rehabilitation services for those with more intensive
              healthcare needs.
            </li>
            <li>
              <strong>In-Home Care:</strong> For seniors preferring to age in
              place, these services provide professional caregivers and medical
              support in the comfort of one's home.
            </li>
            <li className="font-bold mb-4">
              Tip: Explore the Types of Care section on BlueJay® to examine each
              option in depth and determine which is best for your situation.
            </li>
          </ul>
        </section>
        <hr />
        <section>
          <h2 className="text-2xl font-semibold my-4">
            2. Evaluating Senior Living Communities
          </h2>
          <p className="mb-2">
            Choosing the right community is a critical part of the process.
            Here's what to consider:
          </p>
          <h3 className="text-xl font-semibold mb-2">A. Tour the Community:</h3>
          <p className="mb-4">
            Schedule tours at several communities to compare options and assess
            the environment. Observe cleanliness, safety measures, and whether
            residents appear engaged and well-cared for. Talk to staff and
            residents to get a feel for the community atmosphere.
          </p>

          <h3 className="text-xl font-semibold mb-2">
            B. Key Questions to Ask:
          </h3>
          <ul className="list-none pl-5 mb-8">
            <li className="mb-2">
              What are the costs and what's included (e.g., meals, utilities,
              transportation)?
            </li>
            <li className="mb-2">
              Are there extra fees for medical or specialized services?
            </li>
            <li className="mb-2">
              What social and recreational activities are offered?
            </li>
            <li className="mb-2">What are the qualifications of the staff?</li>
            <li>How are emergencies and healthcare needs handled?</li>
          </ul>

          <h3 className="text-xl font-semibold mb-2">
            C. Reviewing Contracts and Policies:
          </h3>
          <p className="mb-4">
            Understand the contract terms, including payment structures,
            cancellation policies, and what services are covered. Make sure the
            community is licensed and accredited according to state regulations.
          </p>

          <p className="font-bold mb-8">
            Pro Tip: Use BlueJay®'s Tour Checklist to stay organized and gather
            all the information you need during your visits.
          </p>
        </section>
        <hr />
        <section>
          <h2 className="text-2xl font-semibold my-4">
            3. Planning for the Transition
          </h2>
          <p className="mb-2">
            The transition to senior living involves more than just moving, it
            requires careful emotional, logistical, and financial planning:
          </p>
          <h3 className="text-xl font-semibold mb-2">
            A. Downsizing and Preparing the Move:
          </h3>
          <p className="mb-4">
            Plan early to make the transition smoother. Sort belongings and
            decide what to keep, donate, or sell. Consider hiring a senior move
            manager or downsizing specialist for extra support. Organize the new
            space to create a sense of familiarity and comfort.
          </p>

          <h3 className="text-xl font-semibold mb-2">
            B. Emotional Adjustment:
          </h3>
          <p className="mb-4">
            Moving can be emotionally challenging. Could you involve loved ones
            in the decision-making process and maintain open communication?
            Focus on senior living opportunities, such as social connections and
            activities. Many communities provide adjustment programs or support
            groups, so consider engaging in these resources to ease the
            transition.
          </p>

          <h3 className="text-xl font-semibold mb-2">
            C. Financial Considerations:
          </h3>
          <p className="mb-4">
            Develop a budget that accounts for monthly costs, insurance, and
            other potential expenses like medical care. Explore financial
            resources such as long-term care insurance, veterans' benefits, or
            reverse mortgages to help fund the transition.
          </p>

          <p className="font-bold mb-8">
            Reminder: Visit our Financial Planning section for more detailed
            information and tips on financing senior living.
          </p>
        </section>
        <hr />
        <section>
          <h2 className="text-2xl font-semibold my-4">
            4. Navigating Legal Considerations
          </h2>
          <p className="mb-2">
            Organizing your legal affairs is crucial to protect your interests
            and those of your loved ones. Here's what to include:
          </p>

          <h3 className="text-xl font-semibold mb-2">A. Wills and Trusts:</h3>
          <p className="mb-4">
            Ensure you have a will that reflects your current wishes, and
            consider setting up a trust to manage your assets efficiently and
            avoid probate. Review and update these documents regularly,
            especially after significant life events.
          </p>

          <h3 className="text-xl font-semibold mb-2">B. Powers of Attorney:</h3>
          <p className="mb-4">
            Assign a durable power of attorney for healthcare and finances to
            someone you trust. They’ll make decisions on your behalf if you
            cannot do so. Communicate your preferences clearly to ensure they
            are well understood.
          </p>

          <h3 className="text-xl font-semibold mb-2">
            C. Advance Healthcare Directives:
          </h3>
          <p className="mb-4">
            This document outlines your healthcare preferences if you cannot
            communicate them yourself. Include details on life-sustaining
            measures, organ donation, and other treatments.
          </p>

          <p className="font-bold mb-8">
            Make sure your healthcare provider and designated power of attorney
            have copies.
          </p>
        </section>
        <hr />
        <section>
          <h2 className="text-2xl font-semibold my-4">
            5. Finding Support and Resources
          </h2>

          <p className="mb-2">
            Navigating senior living doesn’t have to be overwhelming. There are
            plenty of resources to support you:
          </p>

          <ul className="list-none pl-5 mb-8">
            <li className="mb-2">
              <strong>Caregiver Support:</strong> Look for support groups or
              services that offer respite care, counseling, or assistance with
              caregiving responsibilities.
            </li>
            <li className="mb-2">
              <strong>Financial Assistance Programs:</strong> Research state and
              federal programs like Medicaid, Medicare, and veterans’ benefits
              for assistance.
            </li>
            <li>
              <strong>Legal Aid Services:</strong> Some nonprofit organizations
              provide free or low-cost legal services for seniors, such as help
              with wills, trusts, and power of attorney.
            </li>
          </ul>

          <p className="font-bold">
            Explore BlueJay®’s curated list of resources and professional
            organizations to guide you every step of the way.
          </p>
        </section>
        <section>
          <p className="my-10 pt-10 border-black border-t-2">
            <strong>Disclaimer:</strong> BlueJay® provides general advice and
            resources. We do not endorse specific services or professionals.
            Always consult a qualified professional for personalized advice.
          </p>
        </section>
      </div>
    </>
  );
};

export default SeniorLivingGuide;
