import React from "react";
import bannerImg from "../../assets/banners/Advisor-page.png";
import AdvisorsGrid from "../../components/advisor/AdvisorsGrid";
import BannerHeading from "../../components/utils/BannerHeading";

const FindAnAdvisor: React.FC = () => {
  return (
    <>
      <div className="w-full overflow-hidden">
        <BannerHeading
          title="Welcome to Bluejay - Your New Beginning Awaits!"
          width="2/3"
        />
        <div className="w-full h-1/6 mx-auto ">
          <img
            src={bannerImg}
            alt="Hero"
            className="w-full md:h-banner object-cover"
          />
        </div>
      </div>
      <div className="container mx-auto py-8">
        <h2 className="text-3xl font-bold mb-7">We're Thrilled You're Here</h2>
        <p>
          Transitioning into independentliving, assisted living, or memory care
          is a significant step. It often comes with the immediate need to lease
          or sell your current home. Did you know that 80% of folks make this
          move soon after transitioning?
        </p>
        <br />
        <h2 className="text-2xl font-bold mb-4">Meet Your Senior Advisors</h2>
        <p className="mb-4">
          Our Senior Advisors are not just guides to finding the right
          community; they're also your partners in handling the lease or sale of
          your property. They have extensive experience and are prepared to
          support you every step of the way.
        </p>

        <h2 className="text-2xl font-bold mb-4">Comprehensive Vendor List</h2>
        <p className="mb-4">
          Our advisors have access to a comprehensive vendor list, including:
        </p>
        <ul className="list-none pl-5 mb-4">
          <li>
            <strong>Downsizers & Organizers:</strong> Specialists who streamline
            the transition process.
          </li>
          <li>
            <strong>Movers & More:</strong> Not just movers—our teams understand
            how to handle your most treasured pieces, ensuring your heirlooms
            and cherished furniture are moved with care.
          </li>
        </ul>

        <h2 className="text-2xl font-bold mb-4">Virtual Home Design</h2>
        <p className="mb-4">
          Imagine seeing your cherished items arranged in your new home on a
          screen from the comfort of your current home. Our advisors can help
          you design and plan your space digitally, making decisions easy and
          hassle-free.
        </p>

        <h2 className="text-2xl font-bold mb-4">A Graceful Transition</h2>
        <p className="mb-4">
          There’s so much waiting for you, and this is an incredible opportunity
          to gracefully move on to the next chapter of your life. At Bluejay, we
          are here to be useful, to guide, and to ensure your move is as smooth
          and fulfilling as possible.
        </p>
        <p className="font-semibold mb-4">Welcome to your new beginning!</p>
      </div>
      <div className="w-full h-1/6 mx-auto ">
        <img
          src={bannerImg}
          alt="Hero"
          className="w-full md:h-banner object-cover"
        />
      </div>
      <h3 className="text-xxl text-center md:text-right font-bold md:mt-28 p-3 w-full md:w-1/2 border-b-4 border-black">
        Senior Advisors{" "}
        <h2 className="text-4xl text-center md:text-right">
          Serving Your Neighborhood
        </h2>
      </h3>
      <div className="container">
        <AdvisorsGrid initialShowSingleAdvisor={false} />
      </div>
    </>
  );
};

export default FindAnAdvisor;
