import { useEffect, useState } from "react";
import { ScheduleXCalendar, useCalendarApp } from "@schedule-x/react";
import { CalendarEvent } from "@schedule-x/calendar";

import {
  createViewWeek,
  createViewMonthGrid,
  createViewDay,
  createViewMonthAgenda,
  viewMonthGrid,
} from "@schedule-x/calendar";
import "@schedule-x/theme-default/dist/index.css";
import { createEventsServicePlugin } from "@schedule-x/events-service";
import { createEventModalPlugin } from "@schedule-x/event-modal";
import { createDragAndDropPlugin } from "@schedule-x/drag-and-drop";

// import timeGridEvent from "../time-grid-event";
import EventModal from "./EventModal";
import EventUpdate from "./EventUpdate";
import Modal from "../../../../components/utils/Modal";
import CreateEvent from "./CreateEvent";

const defaultEvent: CalendarEvent = {
  id: 0,
  title: "Default Title",
  description: "",
  start: "2024-11-25 02:00",
  end: "2024-11-25 03:00",
  location: "",
  people: [],
  status: "default-status",
  // Any other required properties of CalendarEvent can be set here
};

interface EventProp {
  id: number;
  title: string;
  start: string;
  end: string;
  description: string;
  status: string;
}

const Calendar = () => {
  const [showModal, setShowModal] = useState(false);
  const [showCreateEvent, setShowCreateEvent] = useState(false);
  const [selectedEvent, setSelectedEvent] =
    useState<CalendarEvent>(defaultEvent);
  const eventModal = createEventModalPlugin();
  const calendar = useCalendarApp({
    views: [
      createViewDay(),
      createViewWeek(),
      createViewMonthGrid(),
      createViewMonthAgenda(),
    ],
    plugins: [
      createEventsServicePlugin(),
      createDragAndDropPlugin(),
      eventModal,
    ],
    defaultView: viewMonthGrid.name,
    events: [
      {
        calendarId: "default",
        id: 1,
        title: "Event 1",
        start: "2024-11-06 12:00",
        end: "2024-11-06 16:00",
        description: "Description of event 1",
        people: ["Person 1", "Person 2"],
        status: "confirmed",
      },
      {
        calendarId: "default",
        id: 2,
        title: "Event 2",
        start: "2024-11-25 12:00",
        end: "2024-11-25 13:00",
        people: ["Person 1"],

        description: "Description of event 2",
        status: "cancelled",
      },
    ],
    callbacks: {
      onEventClick(calendarEvent) {
        // console.log("onEventClick", calendarEvent);
        // calendar.events.update({
        //   ...calendarEvent,
        //   title: "Updated Event",
        // });
        openModal(calendarEvent);
        // setShowModal(true);
      },
    },
  });

  const handleCreateEvent = () => {
    setShowCreateEvent(true);
    calendar.events.add({
      id: calendar.events.getAll().length + 1,
      start: "",
      end: "",
    });
  };

  const openModal = (event: any) => {
    setSelectedEvent(event);
    // setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedEvent(defaultEvent);
  };

  const handleSave = (updatedEvent: CalendarEvent) => {
    console.log("from handle save", updatedEvent);
    calendar.events.update(updatedEvent);
  };

  const handleEdit = (calendarEvent: CalendarEvent) => {
    setSelectedEvent(calendarEvent);
    setShowModal(true);
  };

  const handleDelete = (eventId: number) => {
    window.confirm("Are you sure you want to delete this event?");
    if (window.confirm()) {
      console.log("delete event", eventId);
    }
    // this should be in the modal

    handleClose();
    calendar.events.remove(eventId);
  };

  const handleClose = () => {
    if (eventModal) {
      eventModal.close(); // Close the modal
    }
    setSelectedEvent(defaultEvent); // Reset selected event
  };

  useEffect(() => {
    // get all events
    calendar.eventsService.getAll();
  }, []);

  return (
    <>
      <button className="mb-4 btn-tertiary" onClick={() => handleCreateEvent()}>
        Create Event
      </button>

      <ScheduleXCalendar
        calendarApp={calendar}
        customComponents={{
          eventModal: (props) => (
            <EventModal
              calendarEvent={props.calendarEvent}
              onEdit={handleEdit}
              onDelete={handleDelete}
              onClose={handleClose}
            />
          ),
        }}
        // customComponents={{ timeGridEvent: timeGridEvent }}
      />
      {showCreateEvent && (
        <Modal
          isOpen={showCreateEvent}
          onClose={() => setShowCreateEvent(false)}
        >
          <CreateEvent
            onSave={handleSave}
            setShowCreateEvent={setShowCreateEvent}
          />
        </Modal>
      )}
      {showModal && (
        <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
          <EventUpdate
            calendarEvent={selectedEvent}
            // isNewEvent={isNewEvent}
            onSave={handleSave}
            setShowModal={setShowModal}

            // onCancel={closeModal}
          />
        </Modal>
      )}
    </>
  );
};

export default Calendar;
