import { useEffect, useState } from "react";
import { DataTable } from "./LeadTracker/Data-Table";
import { columns } from "./LeadTracker/Columns";
import { Lead2 } from "../../../types/lead";

async function getData(): Promise<Lead2[]> {
  // Fetch data from your API here.
  return [
    {
      id: 1,
      fname: "Sherry",
      lname: "Daily",
      phone: "555-123-4567",
      email: "sherry@example.com",
      address1: "123 Main St",
      address2: "",
      city: "Springfield",
      state: "IL",
      zip: "62701",
      leadStatus: 1,
      agentId: 1,
      leadCustomFields: [],
      leadNotes: [],
      leadTags: [],
      leadTasks: [],
    },
    {
      id: 2,
      fname: "Geoff",
      lname: "Goodman",
      phone: "555-987-6543",
      email: "georff@gmail.com",
      address1: "456 Elm St",
      address2: "",
      city: "Springfield",
      state: "IL",
      zip: "62701",
      leadStatus: 2,
      agentId: 1,

      leadCustomFields: [],
      leadNotes: [],
      leadTags: [],
      leadTasks: [],
    },
    {
      id: 3,
      fname: "Peter",
      lname: "May",
      phone: "555-123-4567",
      email: "pmay@gmail.com",
      address1: "789 Oak St",
      address2: "",
      city: "Springfield",
      state: "IL",
      zip: "62701",
      leadStatus: 3,
      agentId: 1,
      leadCustomFields: [],
      leadNotes: [],
      leadTags: [],
      leadTasks: [],
    },
  ];
}

const Page = () => {
  const [data, setData] = useState<Lead2[] | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch data when the component mounts
    getData()
      .then((fetchedData) => {
        setData(fetchedData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <p>Loading...</p>; // Placeholder for loading state
  }

  if (!data) {
    return <p>No data available.</p>; // Placeholder for no data state
  }

  return (
    <div className="container mx-auto py-10">
      <DataTable columns={columns} data={data} />
    </div>
  );
};

export default Page;
