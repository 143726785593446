import BlogComponent from "../../../../components/blog/BlogComponent";
import { useParams } from "react-router-dom";

const Blog = () => {
  const { blogId } = useParams();

  return (
    <div>
      <BlogComponent blogId={blogId || "default-blog"} />
    </div>
  );
};

export default Blog;
