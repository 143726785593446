import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import axiosInstance from "../../../components/api/axiosInstance"; // Adjust the import path as needed
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MyBooleanInput from "../../../components/utils/MyBooleanInput";

type FormData = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  title: string;
  communityName: string;
  ccldNo: string;
};

const Register: React.FC = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ mode: "onTouched" });

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      const response = await axiosInstance.post(
        "/Authenticate/registercommunitychamp",
        data
      );
      toast.success("Registration successful!");
      console.log("Registration successful:", response.data);
    } catch (error) {
      console.log("Registration failed:", error);
      toast.error("Registration failed. Please try again.");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-center text-primary">
          Become a Community Champion
        </h2>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div>
            <label className="block font-semibold mb-2">First Name</label>
            <input
              type="text"
              {...register("firstName", { required: "First name is required" })}
              className="input-field"
            />
            {errors.firstName && (
              <p className="text-red-500 ">{errors.firstName.message}</p>
            )}
          </div>
          <div>
            <label className="block font-semibold mb-2">Last Name</label>
            <input
              type="text"
              {...register("lastName", { required: "Last name is required" })}
              className="input-field"
            />
            {errors.lastName && (
              <p className="text-red-500 ">{errors.lastName.message}</p>
            )}
          </div>
          <div>
            <label className="block font-semibold mb-2">Phone Number</label>
            <input
              type="text"
              {...register("phoneNumber", {
                required: "Phone number is required",
              })}
              className="input-field"
            />
            {errors.phoneNumber && (
              <p className="text-red-500 ">{errors.phoneNumber.message}</p>
            )}
          </div>

          <div>
            <label className="block font-semibold text-gray-700">Email</label>
            <input
              type="email"
              {...register("email", { required: "Email is required" })}
              className="input-field"
            />
            {errors.email && (
              <p className="text-red-500 ">{errors.email.message}</p>
            )}
          </div>
          <div>
            <label className="block font-semibold text-gray-700">
              Password
            </label>
            <input
              type="password"
              {...register("password", { required: "Password is required" })}
              className="input-field"
            />
            {errors.password && (
              <p className="text-red-500 ">{errors.password.message}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block font-semibold text-gray-700">
              Community Name:
            </label>
            <input
              type="text"
              {...register("communityName", {
                required: "Community Name is required",
              })}
              className="input-field"
            />
            {errors.communityName && (
              <p className="text-red-500 ">{errors.communityName.message}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block font-semibold text-gray-700">CCLD#:</label>
            <input
              type="text"
              placeholder="9 digits"
              {...register("ccldNo", {
                required: "CCLD# is required",
              })}
              className="input-field"
            />
            {errors.ccldNo && (
              <p className="text-red-500 ">{errors.ccldNo.message}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block font-semibold text-gray-700">Title:</label>
            <input
              type="text"
              {...register("title", {
                required: "Title is required",
              })}
              className="input-field"
            />
            {errors.title && (
              <p className="text-red-500 ">{errors.title.message}</p>
            )}
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-primary text-white rounded-md"
          >
            Register
          </button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Register;
