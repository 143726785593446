import { useForm, SubmitHandler } from "react-hook-form";
import axiosInstance from "../../../components/api/axiosInstance"; // Adjust the import path as needed
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MyBooleanInput from "../../../components/utils/MyBooleanInput";

type FormData = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  dreNo: string;
  brokerageAffiliation: string;
  sres: boolean;
  sresWilling: boolean;
};

const Register: React.FC = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ mode: "onTouched" });

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      const response = await axiosInstance.post(
        "/Authenticate/registersenioradvisor",
        data
      );
      toast.success("Registration successful!");
      console.log("Registration successful:", response.data);
    } catch (error) {
      console.log("Registration failed:", error);
      toast.error("Registration failed. Please try again.");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-center text-primary">
          Become a Senior Advisor
        </h2>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div>
            <label className="block font-semibold mb-2">First Name</label>
            <input
              type="text"
              {...register("firstName", { required: "First name is required" })}
              className="input-field"
            />
            {errors.firstName && (
              <p className="text-red-500 ">{errors.firstName.message}</p>
            )}
          </div>
          <div>
            <label className="block font-semibold mb-2">Last Name</label>
            <input
              type="text"
              {...register("lastName", { required: "Last name is required" })}
              className="input-field"
            />
            {errors.lastName && (
              <p className="text-red-500 ">{errors.lastName.message}</p>
            )}
          </div>
          <div>
            <label className="block font-semibold mb-2">Phone Number</label>
            <input
              type="text"
              {...register("phoneNumber", {
                required: "Phone number is required",
              })}
              className="input-field"
            />
            {errors.phoneNumber && (
              <p className="text-red-500 ">{errors.phoneNumber.message}</p>
            )}
          </div>
          <div>
            <label className="block font-semibold mb-2">
              DRE License Number: Include your 9-digit Department of Real Estate
              (DRE) license number
            </label>
            <input
              type="text"
              placeholder="DRE#"
              {...register("dreNo", { required: "DRE number is required" })}
              className="input-field"
            />
            {errors.dreNo && (
              <p className="text-red-500 ">{errors.dreNo.message}</p>
            )}
          </div>
          <div>
            <label className="block font-semibold mb-2">
              Brokerage Affiliation
            </label>
            <input
              type="text"
              {...register("brokerageAffiliation", {
                required: "Brokerage affiliation is required",
              })}
              className="input-field"
            />
            {errors.brokerageAffiliation && (
              <p className="text-red-500 ">
                {errors.brokerageAffiliation.message}
              </p>
            )}
          </div>
          <div>
            <label className="block font-semibold text-gray-700">
              Do you hold a Seniors Real Estate Specialist (SRES) designation
              from the National Association of Realtors (NAR)?
            </label>

            <MyBooleanInput name="sres" control={control} />
            <div>
              <div className="my-2">
                <label className="block font-semibold text-gray-700">
                  Are you willing to become a Seniors Real Estate Specialist
                  (SRES)?
                </label>

                <MyBooleanInput name="sresWilling" control={control} />
              </div>
            </div>
          </div>

          <div>
            <label className="block font-semibold text-gray-700">Email</label>
            <input
              type="email"
              {...register("email", { required: "Email is required" })}
              className="input-field"
            />
            {errors.email && (
              <p className="text-red-500 ">{errors.email.message}</p>
            )}
          </div>
          <div>
            <label className="block font-semibold text-gray-700">
              Password
            </label>
            <input
              type="password"
              {...register("password", { required: "Password is required" })}
              className="input-field"
            />
            {errors.password && (
              <p className="text-red-500 ">{errors.password.message}</p>
            )}
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-primary text-white rounded-md"
          >
            Register
          </button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Register;
