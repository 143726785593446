import React, { useState } from "react";
import { validateAddForm } from "../validation/Validation.tsx";
import useBodyClass from "../../hooks/useBodyClass.tsx";

const AddAddresses = () => {
  const [addresses, setAddresses] = useState<string[]>([
    "123 Main St, Springfield, IL",
    "456 Elm St, Shelbyville, IL",
  ]);
  const [showEditPopup, setShowEditPopup] = useState<boolean>(false);
  const [showRemovePopup, setShowRemovePopup] = useState<boolean>(false);
  const [currentAddress, setCurrentAddress] = useState<string | null>(null);
  const [formData, setFormData] = useState({
    addressType: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    poBox: false,
  });
  const [errors, setErrors] = useState<any>({});
  const [focusedField, setFocusedField] = useState<string | null>(null);

  useBodyClass("popup-active", showEditPopup);

  const handleEdit = (address: string) => {
    setCurrentAddress(address);
    setShowEditPopup(true);
  };

  const handleRemove = (address: string) => {
    setCurrentAddress(address);
    setShowRemovePopup(true);
  };

  const confirmRemove = () => {
    setAddresses(addresses.filter((addr) => addr !== currentAddress));
    setShowRemovePopup(false);
    setCurrentAddress(null);
  };

  const handleAddNew = () => {
    setCurrentAddress(null);
    setShowEditPopup(true);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const target = e.target as HTMLInputElement | HTMLSelectElement;
    const { name, value } = target;
    const type = (target as HTMLInputElement).type;
    const checked = (target as HTMLInputElement).checked;

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    // validateAddForm(formData, setErrors);
  };

  const handleSubmit = () => {
    if (validateAddForm(formData, setErrors)) {
      const newAddress = `${formData.address1}, ${formData.address2}, ${formData.city}, ${formData.state}, ${formData.zip}, ${formData.country}`;

      if (currentAddress) {
        // Update address logic here
        setAddresses(
          addresses.map((addr) => (addr === currentAddress ? newAddress : addr))
        );
      } else {
        // Add new address logic here
        setAddresses([...addresses, newAddress]);
      }

      setShowEditPopup(false);
      setCurrentAddress(null);
      setFormData({
        addressType: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        poBox: false,
      });
    } else {
      // this needs to activate all error messages. The onBlur and onFocus events are interfering.
    }
  };

  const handleFocus = (
    e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFocusedField(e.target.name);
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFocusedField(null);
    validateAddForm(formData, setErrors);
  };

  return (
    <div className="mb-8 md:flex-1 flex flex-col">
      <h2 className="text-xl font-semibold mb-4">My Saved Addresses</h2>
      {addresses.length === 0 ? (
        <p className="text-gray-500">N/A</p>
      ) : (
        addresses.map((address, index) => (
          <div key={index} className="mb-4 p-4 border rounded shadow-sm">
            <p className="mb-2">{address}</p>
            <button
              className="text-blue-500 hover:underline mr-4"
              onClick={() => handleEdit(address)}
            >
              Edit
            </button>
            <button
              className="text-error hover:underline"
              onClick={() => handleRemove(address)}
            >
              Remove
            </button>
          </div>
        ))
      )}
      <button
        className="mt-4 p-2 bg-primary text-accent rounded hover:bg-secondary"
        onClick={handleAddNew}
      >
        Add New Address
      </button>

      {showEditPopup && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-lg w-96 max-h-[80vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">
                {currentAddress ? "Edit Address" : "Add New Address"}
              </h3>
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={() => setShowEditPopup(false)}
              >
                &times;
              </button>
            </div>
            <div className="mb-4">
              <label className="block mb-1">Address Type</label>
              <select
                name="addressType"
                className="w-full p-2 border rounded"
                value={formData.addressType}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
              >
                <option value="">Select Address Type</option>
                <option value="Business">Business</option>
                <option value="District">District</option>
                <option value="Home">Home</option>
                <option value="Higher-Education Institution">
                  Higher-Education Institution
                </option>
                <option value="Other Organisation">Other Organisation</option>
                <option value="School">School</option>
              </select>
              {focusedField === "addressType" && errors.addressType && (
                <p className="text-error text-sm">{errors.addressType}</p>
              )}
            </div>
            <div className="mb-4">
              <label className="block mb-1">Address 1</label>
              <input
                type="text"
                name="address1"
                className="w-full p-2 border rounded"
                value={formData.address1}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
              {focusedField === "address1" && errors.address1 && (
                <p className="text-error text-sm">{errors.address1}</p>
              )}
            </div>
            <div className="mb-4">
              <label className="block mb-1">Address 2</label>
              <input
                type="text"
                name="address2"
                className="w-full p-2 border rounded"
                value={formData.address2}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1">City</label>
              <input
                type="text"
                name="city"
                className="w-full p-2 border rounded"
                value={formData.city}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
              {focusedField === "city" && errors.city && (
                <p className="text-error text-sm">{errors.city}</p>
              )}
            </div>
            <div className="mb-4">
              <label className="block mb-1">State/Province/Region</label>
              <input
                type="text"
                name="state"
                className="w-full p-2 border rounded"
                value={formData.state}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
              {focusedField === "state" && errors.state && (
                <p className="text-error text-sm">{errors.state}</p>
              )}
            </div>
            <div className="mb-4">
              <label className="block mb-1">Zip/Postal Code</label>
              <input
                type="text"
                name="zip"
                className="w-full p-2 border rounded"
                value={formData.zip}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
              {focusedField === "zip" && errors.zip && (
                <p className="text-error text-sm">{errors.zip}</p>
              )}
            </div>
            <div className="mb-4">
              <label className="block mb-1">Country</label>
              <select
                name="country"
                className="w-full p-2 border rounded"
                value={formData.country}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
              >
                <option value="">Select Country</option>
                <option value="USA">USA</option>
                <option value="Canada">Canada</option>
                <option value="UK">UK</option>
                {/* Add more countries as needed */}
              </select>
              {focusedField === "country" && errors.country && (
                <p className="text-error text-sm">{errors.country}</p>
              )}
            </div>
            <div className="mb-4">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  name="poBox"
                  className="form-checkbox"
                  checked={formData.poBox}
                  onChange={handleChange}
                />
                <span className="ml-2">P.O. Box</span>
              </label>
            </div>
            <div className="flex justify-end">
              <button
                className="mr-4 p-2 bg-gray-300 rounded hover:bg-gray-400"
                onClick={() => setShowEditPopup(false)}
              >
                Cancel
              </button>
              <button
                className="p-2 bg-primary text-accent rounded hover:bg-secondary"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {showRemovePopup && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-lg">
            <h3 className="text-lg font-semibold mb-4">Are you sure?</h3>
            <div className="flex justify-end">
              <button
                className="mr-4 p-2 bg-gray-300 rounded hover:bg-gray-400"
                onClick={() => setShowRemovePopup(false)}
              >
                Cancel
              </button>
              <button
                className="p-2 bg-red-500 text-white rounded hover:bg-red-600"
                onClick={confirmRemove}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddAddresses;
