import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SeniorAdvisorProfile from "./advisorAdmin/AdvisorProfile";
import SeniorLeadCardOverview from "../InfoCard/Components/SeniorLeadCardOverview.tsx";
import TaskTracker from "../InfoCard/Components/Task/TaskTracker.tsx";
import { Advisor } from "../../../types/advisor";
import axiosInstance from "../../api/axiosInstance.tsx";
import { useUserContext } from "../../../context/UserContext.tsx";

const SeniorAdvisorDashboard = () => {
  const { user } = useUserContext();

  const [advisorData, setAdvisorData] = useState<Advisor>();
  useEffect(() => {
    axiosInstance
      .get(`/Agents/${user?.id}`)
      .then((response) => {
        setAdvisorData(response.data);
        console.log("advisor data", user);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return (
    <div className="container">
      <div className="grid grid-cols-1 md:grid-flow-row md:grid-cols-4 gap-4 mb-4">
        <div className="">
          <SeniorAdvisorProfile advisor={advisorData} />
          <Link
            to="/senior-advisor/123"
            className="block btn-primary text-center mt-6"
          >
            Preview Public View
          </Link>
        </div>
        <div className="md:col-span-2">
          <SeniorLeadCardOverview />
        </div>
        <div className="border p-4 rounded-lg shadow-md ">
          <TaskTracker />
        </div>
      </div>
    </div>
  );
};

export default SeniorAdvisorDashboard;
