import React, { useState } from "react";
import TermsAndConditions from "./communityChampTermsConditions";
import Modal from "../../utils/Modal";

// Profile Upload Component
const ProfilePhotoUpload: React.FC = () => {
  return (
    <div className="flex flex-col md:flex-row items-center mb-6">
      <img
        src={"https://via.placeholder.com/150"}
        alt="placeholder for profile pic"
        className="w-24 h-24 rounded-full mb-4 md:mr-4"
      />

      <input
        className="block w-1/2 p-4 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50  focus:outline-none  "
        id="image_input"
        type="file"
        accept="image/*"
      />
    </div>
  );
};

// Input Field Component
interface InputFieldProps {
  label: string;
  type: string;
  placeholder: string;
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  type,
  placeholder,
}) => {
  return (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <input
        type={type}
        placeholder={placeholder}
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
      />
    </div>
  );
};

// Checkbox Field Component
interface CheckboxFieldProps {
  label: string;
  options: string[];
}

const CheckboxField: React.FC<CheckboxFieldProps> = ({ label, options }) => {
  return (
    <div className="mb-6 border p-3">
      <label className="block text-sm font-medium text-gray-700 mb-2">
        {label}
      </label>
      <div className="grid grid-cols-2 gap-4">
        {options.map((option) => (
          <label key={option} className="flex items-center">
            <input
              type="checkbox"
              className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
            />
            <span className="ml-2 text-sm text-gray-700">{option}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

// Main Component
const CommunityChampionProfile: React.FC = () => {
  const [description, setDescription] = useState("");
  const [depositInfo, setDepositInfo] = useState("");
  const [additionalCosts, setAdditionalCosts] = useState("");
  const [staffRatio, setStaffRatio] = useState("");

  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

  const handleTermsAndConditions = () => {
    // Handle terms and conditions
    setShowTermsAndConditions(true);
  };

  return (
    <div className="max-w-4xl mx-auto p-8 bg-white shadow-md rounded-lg">
      <h1 className="text-2xl font-semibold mb-8">Community Champion</h1>

      {/* Profile Photo Upload */}
      <ProfilePhotoUpload />

      {/* Basic Information Section */}
      <div className="grid grid-cols-2 gap-4 mb-8">
        <InputField label="First Name" type="text" placeholder="First name" />
        <InputField label="Last Name" type="text" placeholder="Last name" />
        <InputField label="Title" type="text" placeholder="Title" />
        <InputField
          label="Phone Number"
          type="tel"
          placeholder="Phone number"
        />
        <InputField label="Email" type="email" placeholder="Email" />
      </div>

      {/* Community Information Section */}
      <div className="mb-8">
        <h2 className="text-lg font-semibold mb-4">Community Information</h2>
        <InputField
          label="Community Name"
          type="text"
          placeholder="Community Name"
        />
        <p className="my-2">Department of Social Services (CDSS)</p>
        <p className="my-2"> The Community Care Licensing Division (CCLD)</p>

        <InputField label="CCLD#" type="text" placeholder="9 digits" />
        <InputField
          label="Community Capacity"
          type="number"
          placeholder="Community capacity"
        />

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Waitlist Availability
          </label>
          <div className="flex space-x-4">
            <label className="flex items-center">
              <input
                type="radio"
                name="waitlist"
                className="form-radio h-4 w-4"
              />
              <span className="ml-2 text-sm text-gray-700">Yes</span>
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                name="waitlist"
                className="form-radio h-4 w-4"
              />
              <span className="ml-2 text-sm text-gray-700">No</span>
            </label>
          </div>
        </div>

        {/* Location and Description */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Location
          </label>
          <textarea
            rows={4}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
            placeholder="Provide the full address, city, state, and zip code."
          />
        </div>

        {/* Description */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Community Description
          </label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            maxLength={900}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
            placeholder="Enter description (max 900 characters)"
            rows={4}
          />
          <div className="text-sm text-gray-500 mt-1 text-right">
            {description.length}/900
          </div>
        </div>

        {/* Photo and Video Upload */}
        <div className="flex flex-col space-y-4">
          <label className="block text-sm font-medium text-gray-900 ">
            Upload Image
          </label>
          <input
            className="block w-full p-4 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50  focus:outline-none  "
            id="image_input"
            type="file"
            accept="image/*"
          />

          <label className="block  text-sm font-medium text-gray-900 ">
            Upload Video
          </label>
          <input
            className="block w-full p-4 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50  focus:outline-none"
            id="video_input"
            type="file"
            accept="video/*"
          />
        </div>
      </div>

      {/* Community Type Section */}
      <CheckboxField
        label="Community Type (Multiple Selection Options)"
        options={[
          "Independent Living",
          "Assisted Living",
          "Memory Care",
          "Skilled Nursing Facility",
          "55+ Active Adult Community",
          "Respite Care",
          "Hospice Care",
          "Other",
        ]}
      />

      {/* Services and Amenities Section */}
      <CheckboxField
        label="Services and Amenities"
        options={[
          "Memory Care Programs",
          "Social and Recreational Activities",
          "Therapy Services (psyical, occupational, speech)",
          "Fitness and Wellness Programs",
          "Concierge Services",
          "Medication Management",
          "Personal Care Assistance (bathing, dressing, grooming)",
          "House keeping and Laundry Services",
          "Meal Services (number of meals per day)",
          "Transportation Services",
          "Spiritual Services",
          "Pet-Friendly Options",
        ]}
      />

      {/* Room and Accommodation Section */}
      <CheckboxField
        label="Detail Categories"
        options={[
          "Shared Room",
          "Semi-Private Room",
          "Private Room",
          "Studio",
          "Furnished Room",
          "Balcony",
          "Private Bathroom",
          "Unfurnished Room",
          "Closet Space",
          "Two Bedroom",
          "One Bedroom",
          "Patio",
          "In-room Kitchenette",
        ]}
      />

      {/* Service and Community Features Section */}
      <CheckboxField
        label="Service and Community Features"
        options={[
          "Emergency Call System",
          "Visitor Hours",
          "Storage Options",
          "House Keeping Frequency",
          "Security Features",
          "Phone",
          "Smoking Policy",
          "Wi-Fi",
          "Laundry Service",
          "Pet-friendly",
          "Visitor Hours",
          "Cable TV",
          "Security Features",
          "Accessibility Features",
        ]}
      />

      <CheckboxField
        label="Room Features"
        options={[
          "Private Bathroom",
          "Balcony/Patio",
          "Cable/Satelite TV",
          "In-Unit Laundry",
          "Kitchenette",
          "Wifi",
        ]}
      />
      <CheckboxField
        label="Pricing"
        options={[
          "$2500 - $4000",
          "$4000 - $6500",
          "$6500 - $9000",
          "$9000 - $11500+",
        ]}
      />

      <CheckboxField
        label="Payment Options"
        options={[
          "Private Pay",
          "Medicare",
          "Medicaid",
          "Long Term Care Insurance",
          "Veteran's Benefits",
          "Other",
        ]}
      />

      <div className="my-6">
        <h3 className="text-lg font-semibold">Deposit or Entrance Fees</h3>
        <textarea
          className="w-full border p-2 rounded"
          value={depositInfo}
          onChange={(e) => setDepositInfo(e.target.value)}
          placeholder="Specify any entry fees, deposits, or buy-ins for CCRCs."
          maxLength={900}
        />
        <p className="text-sm text-gray-500">{depositInfo.length}/900</p>
      </div>

      <div className="my-6">
        <h3 className="text-lg font-semibold">Additional Costs</h3>
        <textarea
          className="w-full border p-2 rounded"
          value={additionalCosts}
          onChange={(e) => setAdditionalCosts(e.target.value)}
          placeholder="Outline extra costs for services such as housekeeping, meal plans, or special care."
          maxLength={900}
        />
        <p className="text-sm text-gray-500">{additionalCosts.length}/900</p>
      </div>

      <div className="my-6">
        <h3 className="text-lg font-semibold">Care and Staffing</h3>
        <label className="flex items-center space-x-2">
          <span>Staff-to-Resident Ratio</span>
          <input
            type="text"
            className="border rounded p-2 w-24"
            value={staffRatio}
            onChange={(e) => setStaffRatio(e.target.value)}
          />
        </label>
      </div>

      <CheckboxField
        label="On-Site Medical Staff"
        options={[
          "Physical Therapists",
          "On-Call Doctors",
          "Nurse Practitioners (NP)",
          "Registered Nurses (RN)",
          "Licensed Practical Nurses (LPN)",
          "Certified Nursing Assistants (CNA)",
        ]}
      />

      <CheckboxField
        label="Emergency Care: (Checkbox or Multi-Select)"
        options={[
          "Call button or Pendant Systems",
          "24/7 Emergency Response Systems",
        ]}
      />

      <CheckboxField
        label="Activity Categories Fitness and Wellness (Checkbox or Multi-Select):"
        options={[
          "Fitness Classes",
          "Yoga and Meditation",
          "Walking Clubs",
          "Swimming",
          "Water Aerobics",
          "Dance Classes",
        ]}
      />

      <CheckboxField
        label="Arts and Creativity"
        options={["Arts and Crafts", "Music Therapy", "Cooking Classes"]}
      />

      <CheckboxField
        label="Social and Cultural Activities"
        options={[
          "Social Events",
          "Volunteer Opportunities",
          "Book Clubs",
          "Movie Nights",
          "Religious Services",
          "Game Nights",
          "Group Outings",
        ]}
      />

      <CheckboxField
        label="Educational and Development"
        options={["Educational Workshops", "Technology Classes", "Brain Games"]}
      />

      <CheckboxField
        label="Therapeutic Activities"
        options={["Gardening", "Pet Therapy"]}
      />

      <CheckboxField
        label="Care Categories (Checkbox or Multi-Select):"
        options={[
          "Assisted Living",
          "Independent Living",
          "Memory Care",
          "Skilled Nursing Care",
          "Respite Care",
          "Hospice Care",
        ]}
      />

      <CheckboxField
        label="Health and Wellness Management"
        options={[
          "Medication Management",
          "24-Hour Supervision",
          "Personal Care Assistance",
          "Mobility Assistance",
          "Incontinence Care",
          "Bathing and Dressing Assistance",
          "Diabetes Management",
          "Alzheimer’s/Dementia Care",
        ]}
      />

      <CheckboxField
        label="Rehabilitation and Therapy (Checkbox or Multi-Select):"
        options={[
          "On-site Rehabilitation",
          "Physical Therapy",
          "Occupational Therapy",
          "Speech Therapy",
          "Palliative Care",
        ]}
      />

      <CheckboxField
        label="Specialized Care"
        options={["Chronic Conditions Care"]}
      />

      <CheckboxField
        label="Deposits/Fees Categories (Checkbox or Multi-Select):"
        options={[
          "Initial Fees",
          "Application Fee",
          "Entrance Fee",
          "Community Fee",
          "Security Deposit",
          "Pet Deposit",
        ]}
      />

      <CheckboxField
        label="Ongoing Fees (Checkbox or Multi-Select):"
        options={[
          "Monthly Service Fee",
          "Utility Fee",
          "Second Occupant Fee",
          "Move-in Fee",
          "Meal Plan Fee",
          "Cable/Internet Fee",
          "Maintenance Fee",
          "Laundry Service Fee",
          "Renovation Fee",
          "Late Payment Fee",
          "Parking Fee",
          "Transportation Fee",
          "Amenity Fee",
          "Care Assessment Fee",
        ]}
      />

      <CheckboxField
        label="Dining Categories (Checkbox or Multi-Select):"
        options={[
          "Restaurant-style",
          "In-room Dining",
          "Buffet-style",
          "Café",
          "Bistro",
          "Private Dining Rooms",
        ]}
      />

      <CheckboxField
        label="Diet and Nutrition (Checkbox or Multi-Select):"
        options={[
          "Specialized Diets",
          "Ethnic Cuisine Options",
          "Culinary Events",
          "Assisted Dining",
          "Outdoor Dining",
          "Nutritionist-approved Meals",
          "Farm-to-table",
          "Snacks and Refreshments",
        ]}
      />
      <CheckboxField
        label="Resident and Family Support (Checkbox or Multi-Select): Family Involvement"
        options={[
          "Communication Tools (such as family portals)",
          "Family Caregiver Support Programs",
          "Scheduled Visits and Family Events",
        ]}
      />

      <CheckboxField
        label="Transportation Options:"
        options={[
          "Scheduled Group Transportation (shopping, appointments)",
          "Private Transportation Services (for personal use)",
        ]}
      />

      <CheckboxField
        label="Security Features:"
        options={[
          "24 Hour Security Staff",
          "Secure Entry/Exit Points",
          "Camera Surveillance",
          "Wander Guard Systems (for memory care)",
        ]}
      />

      <button className="btn-primary" onClick={handleTermsAndConditions}>
        Finish
      </button>
      <Modal
        isOpen={showTermsAndConditions}
        onClose={() => setShowTermsAndConditions(false)}
      >
        <TermsAndConditions />
      </Modal>
    </div>
  );
};

export default CommunityChampionProfile;
