import React from "react";
import BannerImg from "../../components/utils/BannerImg";
import bannerImg from "../../assets/banners/blog.png";
import img1 from "../../assets/23.png";
import BannerHeading from "../../components/utils/BannerHeading";
import BlogPosts from "../../components/admin/SuperAdmin/BlogPostDisplay";

const Blog: React.FC = () => {
  return (
    <div className="mb-10 pb-10 border-black border-b-4">
      <div className="w-full overflow-hidden">
        <BannerHeading title="Welcome to the BlueJay Blog" width="2/3" />
        <div className="w-full overflow-hidden ">
          <BannerImg imgUrl={bannerImg} />
        </div>
      </div>

      <div className="container mx-auto px-6 lg:px-16 py-8">
        <section className="flex flex-col lg:flex-row">
          {/* Main Text */}
          <div className="lg:w-2/3 pr-20">
            <h2 className="text-2xl font-bold mb-4">
              Navigating Life’s Transitions with Confidence
            </h2>
            <p className="mb-4">
              At Bluejay, we understand the unique challenges and emotions that
              come with transitioning into senior living. Our blog is crafted to
              support seniors and their families who often navigate these
              changes alongside them. Whether it’s downsizing, managing family
              dynamics, or dealing with the stress of selling the family home,
              we’re here to offer guidance and reassurance every step of the
              way.
            </p>
          </div>

          {/* Side Image */}
          <div className="lg:w-1/3 flex justify-center lg:justify-end mt-8 lg:mt-0">
            <div className="relative">
              <img
                src={img1}
                alt="Featured"
                className="w-full h-auto rounded-md shadow-lg"
              />
            </div>
          </div>
        </section>

        {/* Additional Content */}
        <section className="mt-12">
          <h3 className="text-xl font-semibold mb-2">
            Explore Insightful and Compassionate Posts
          </h3>
          <p className="font-semibold my-2">
            From practical advice to heartfelt stories, dive into content
            designed to support and inform:
          </p>
          <ul className="list-none list-inside space-y-2 mb-2 *:pl-5">
            <li>
              <strong>Senior Living Guides:</strong> Comprehensive articles on
              choosing the right senior living community, tailored to meet
              diverse needs and preferences.
            </li>
            <li>
              <strong>Wellness and Lifestyle Tips:</strong> Enhance your daily
              living with health and wellness advice specifically curated for
              older adults.
            </li>
            <li>
              <strong>Financial and Legal Guidance:</strong> Navigate the
              complexities of estate planning, insurance, and real estate
              transactions with expert insights.
            </li>
            <li>
              <strong>Caregiver Resources:</strong> Essential tips and support
              systems for caregivers balancing personal lives with the demands
              of caregiving.
            </li>
            <li>
              <strong>Real Stories, Real Inspiration:</strong> Connect with
              stories from community members who have embraced new chapters of
              their lives and found joy and fulfillment in senior living.
            </li>
          </ul>
          <h3 className="text-xl font-semibold mt-8 mb-2">
            Stay Connected and Informed
          </h3>
          <p className="text-lg font-semibold mb-4">
            Join our community of readers:
          </p>
          <ul className="list-none list-inside space-y-2 mb-8 *:pl-6">
            <li>
              <strong>Subscribe to our newsletter:</strong> Get the latest
              updates, blog posts, and stories delivered directly to your inbox.
            </li>
            <li>
              <strong>Engagement and Feedback:</strong> We cherish your thoughts
              and experiences. Please reach out if you have a story to share, a
              topic you want us to explore, or questions about transitioning
              into senior living. Your input helps us make our community
              stronger and more connected.
            </li>
          </ul>

          <h3 className="text-xl font-semibold mb-4">You're Not Alone</h3>
          <p className="text-lg">
            Transitioning into a new phase of life can be daunting, but it’s
            also an opportunity for growth and new experiences. At Bluejay, we
            believe in shedding light on these transitions, showing that while
            the path may seem challenging, there is a light at the end of the
            tunnel that leads to good and fulfilling places.
          </p>
          <p className="text-lg mt-4">
            Visit our blog to find comfort, guidance, and the reassurance that
            you’re taking these steps together with a supportive community
            behind you. Let’s navigate this journey together with grace and
            knowledge.
          </p>
        </section>
      </div>

      {/* Blog Posts */}
      <BlogPosts />
    </div>
  );
};

export default Blog;
