import React, { useState, useEffect } from "react";
import TaskTracker from "../InfoCard/Components/Task/TaskTracker.tsx";
import axiosInstance from "../../api/axiosInstance.tsx";
import MessageController from "../InfoCard/Components/MessageController.tsx";

interface careChampionData {
  fname: string;
  lname: string;
  email: string;
  phone: string;
  address: string;
  stage: string;
  tags: string[];
  customFields: string[];
}

const Profile: React.FC = () => {
  const [careChampionData, setcareChampionData] = useState<any>({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    address1: "",
    stage: "",
    tags: [],
    customFields: [],
  });

  // consume user info
  useEffect(() => {
    axiosInstance.get("/leads/1").then((response) => {
      console.log("lead data", response.data);
      setcareChampionData(response.data);
    });
  }, []);

  return (
    <div className="p-6 bg-white shadow-lg rounded-md max-w-[500px] lg:w-1/2">
      <div className="flex space-x-4">
        {/* Left column */}
        <div className="w-full space-y-4 border p-4 rounded-md mb-4">
          <div className="">
            <div className="flex items-center">
              <img
                className="w-24 h-24 rounded-full"
                src={"https://via.placeholder.com/150"}
                alt="User Avatar"
              />
              <span className="font-semibold m-2">
                {careChampionData.fname}
              </span>
              <span className="font-semibold m-2">
                {careChampionData.lname}
              </span>
            </div>
            <div className="m-3">
              <p className="mt-2">{careChampionData.phone}</p>
              <p className="mt-2">{careChampionData.email}</p>
              <p className="mt-2">{careChampionData.address1}</p>
              {/* <label className="block mb-2">
      {label}
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        className={className}
      />
    </label> */}
              <input
                type="text"
                id="phone"
                name="phone"
                placeholder="placeholder"
                className="block whitespace-nowrap overflow-hidden text-ellipsis w-40"
              />
              <input
                type="text"
                id="email"
                name="email"
                placeholder="placeholder"
                className="block whitespace-nowrap overflow-hidden text-ellipsis w-40"
              />
              <input
                type="text"
                id="address1"
                name="address1"
                placeholder="placeholder"
                className="block whitespace-nowrap overflow-hidden text-ellipsis w-40"
              />
            </div>
          </div>
        </div>
      </div>
      <button className="btn-tertiary ">Save</button>
    </div>
  );
};

export default Profile;
