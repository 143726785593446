import { useEffect, useState } from "react";
import axiosInstance from "../../components/api/axiosInstance.tsx";
import SeniorAdvisor from "../../components/advisor/SeniorAdvisor.tsx";
import { useParams } from "react-router-dom";
import { Advisor } from "../../types/advisor";

const SeniorAdvisorPage = () => {
  const { advisorId } = useParams(); // use this to fetch advisor data
  const [advisorData, setAdvisorData] = useState<Advisor>();

  useEffect(() => {
    // Fetch advisor data using advisor
    axiosInstance.get(`/Agents/${advisorId}`).then((response) => {
      setAdvisorData(response.data);
    });
  }, []);

  // Fetch advisor data using advisorId (this is just a placeholder)

  return (
    <div className="container mx-auto p-4">
      <SeniorAdvisor advisor={advisorData} />
    </div>
  );
};

export default SeniorAdvisorPage;

// const advisorData = {
//   photo: "https://via.placeholder.com/150",
//   name: "John Doe",
//   title: "Senior Advisor",
//   license: " #12345",
//   directPhone: "123-456-7890",
//   cellPhone: "098-765-4321",
//   email: "john.doe@example.com",
//   credentials: [
//     "Certified Senior Real Estate Specialist (SRES@)",
//     "Licensed Real Estate Broker",
//   ],
//   services: [
//     "Senior Living Transitions",
//     "Property Valuation",
//     "Home Selling and Buying",
//     "Estate Planning Support",
//   ],
//   awards: ["Top Producer Award, 2023", "Excellence in Client Service, 2022"],
//   testimonials: ["John is great!", "Highly recommend John."],
//   yearsInBusiness: 15,
//   areasServed: ["Greater Los Angeles", "Including the Beaches"],
//   followMeLinks: [
//     { platform: "LinkedIn", url: "https://linkedin.com/in/johndoe" },
//     { platform: "Facebook", url: "https://facebook.com/johndoe" },
//     { platform: "Instagram", url: "https://instagram.com/johndoe" },
//   ],
//   summary:
//     "John Doe is a Senior Advisor with 15 years of experience in real estate. He specializes in helping seniors transition to new living arrangements and provides support for estate planning.",
// };
