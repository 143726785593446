import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../../api/axiosInstance";

interface CustomField {
  id: number;
  customField: string;
  leadId: number;
}

interface CreateNewFieldProps {
  onAddField: (selectedFields: CustomField[]) => void;
  closeModal: () => void;
}

//UseEffct to bring in all the standard Custom Fields
const CreateNewField: React.FC<CreateNewFieldProps> = ({
  onAddField,
  closeModal,
}) => {
  const [fields, setFields] = useState<CustomField[]>([
    {
      id: 100,
      leadId: 1,
      customField: "Work Website",
    },
    {
      id: 102,
      leadId: 1,
      customField: "Twitter",
    },
    {
      id: 103,
      leadId: 1,
      customField: "LinkedIn",
    },
    {
      id: 4,
      leadId: 1,
      customField: "Facebook",
    },
    {
      id: 5,
      leadId: 1,
      customField: "Title",
    },
    {
      id: 6,
      leadId: 1,
      customField: "Website",
    },
    {
      id: 7,
      leadId: 1,
      customField: "Birthday",
    },
    {
      id: 8,
      leadId: 1,
      customField: "Close Date / Home Anniversary",
    },
    {
      id: 9,
      leadId: 1,
      customField: "Commission",
    },
    {
      id: 10,
      leadId: 1,
      customField: "Spouse Name",
    },
    {
      id: 11,
      leadId: 1,
      customField: "Spouse Birthday",
    },
    {
      id: 12,
      leadId: 1,
      customField: "Company",
    },
    {
      id: 13,
      leadId: 1,
      customField: "Sober birthday",
    },
    {
      id: 14,
      leadId: 1,
      customField: "Son's birthday",
    },
    {
      id: 15,
      leadId: 1,
      customField: "Daughter's birthday",
    },
    {
      id: 16,
      leadId: 1,
      customField: "#2 Son birthday",
    },
    {
      id: 17,
      leadId: 1,
      customField: "#2 Son name",
    },
    {
      id: 18,
      leadId: 1,
      customField: "Daughter name",
    },
    {
      id: 19,
      leadId: 1,
      customField: "Son's name",
    },
    {
      id: 20,
      leadId: 1,
      customField: "Dog's name",
    },
    {
      id: 21,
      leadId: 1,
      customField: "Wedding anniversary",
    },
    {
      id: 22,
      leadId: 1,
      customField: "#2 Daughter's name",
    },
  ]);

  const [selectedFields, setSelectedFields] = useState<number[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [newFieldName, setNewFieldName] = useState(""); // State for the new field name

  const handleAddNewField = () => {
    if (!newFieldName.trim()) return; // Avoid empty names
    axiosInstance
      .post("/LeadCustomFields", {
        id: 4,
        leadId: 1,
        customField: newFieldName,
      })
      .then((res) => {
        const newField = res.data;
        setFields([...fields, newField]); // Add the new field to the list
        setSearchQuery(""); // Reset the search query
        setNewFieldName(""); // Clear the new field input
        onAddField(newField);
        closeModal();
      })
      .catch((err) => {
        console.error("Error adding new field:", err);
      });
  };

  const toggleFieldSelection = (id: number) => {
    if (selectedFields.includes(id)) {
      setSelectedFields(selectedFields.filter((fieldId) => fieldId !== id));
    } else {
      setSelectedFields([...selectedFields, id]);
    }
  };

  const handleSave = () => {
    const selectedFieldObjects = fields.filter((field) =>
      selectedFields.includes(field.id)
    );
    onAddField(selectedFieldObjects); // Pass only the selected fields to parent
    document.body.style.overflow = "";
    closeModal();
  };

  const filteredFields = fields.filter((field) =>
    field.customField.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="p-4 w-full max-w-lg mx-auto bg-white rounded-lg shadow-lg">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Tags</h2>
        <FontAwesomeIcon
          icon={faTimes}
          className="cursor-pointer text-gray-500 hover:text-gray-700"
          onClick={closeModal}
        />
      </div>

      {/* Search Input */}
      <div className="relative mb-4">
        <input
          type="text"
          className="w-full p-2 border rounded-md pl-10"
          placeholder="Search or create a new tag"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <FontAwesomeIcon
          icon={faSearch}
          className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"
        />
      </div>

      {/* List of Fields */}
      <div className="h-40 overflow-y-auto mb-4 border rounded-md">
        <ul>
          {filteredFields.length > 0 ? (
            filteredFields.map((field) => (
              <li
                key={field.id}
                className="flex items-center p-2 hover:bg-gray-100"
              >
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={selectedFields.includes(field.id)}
                  onChange={() => toggleFieldSelection(field.id)}
                />
                <span>{field.customField}</span>
              </li>
            ))
          ) : (
            <li className="p-2 text-gray-500">
              <span>No matching fields found</span>
              <div className="mt-2 w-full flex">
                <input
                  type="text"
                  className="flex-grow p-2 border rounded-md"
                  placeholder="Create a new tag"
                  value={newFieldName}
                  onChange={(e) => setNewFieldName(e.target.value)}
                />
                <button
                  onClick={handleAddNewField}
                  className="ml-2 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                >
                  Add
                </button>
              </div>
            </li>
          )}
        </ul>
      </div>

      {/* Footer Buttons */}
      <div className="flex justify-end space-x-2">
        <button
          onClick={closeModal}
          className="px-4 py-2 text-gray-600 hover:underline"
        >
          Cancel
        </button>
        <button
          onClick={handleSave}
          className={`px-4 py-2 bg-blue-500 text-white rounded-md ${
            selectedFields.length === 0
              ? "opacity-50 cursor-not-allowed"
              : "hover:bg-blue-600"
          }`}
          disabled={selectedFields.length === 0}
        >
          Save Fields
        </button>
      </div>
    </div>
  );
};

export default CreateNewField;
