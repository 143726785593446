import { Link } from "react-router-dom";
import logo from "../../assets/logo-footer.png";

const Footer = () => {
  return (
    <footer className="bg-primary text-white py-8">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row justify-between">
          <div>
            <div>
              <div className="text-lg font-bold my-2">Quick Links</div>
              <ul className="flex flex-wrap">
                <li>
                  <Link
                    to="/"
                    className="mr-4 hover:text-gray-300"
                    aria-label="Home"
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/our-story"
                    className="mr-4 hover:text-gray-300"
                    aria-label="About Us and Our Story"
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    Our Story
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className="mr-4 hover:text-gray-300"
                    aria-label="Contact Us"
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/faq"
                    className="mr-4 hover:text-gray-300"
                    aria-label="FAQs"
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    FAQs
                  </Link>
                </li>
                <li>
                  <Link
                    to="/search-results"
                    className="mr-4 hover:text-gray-300"
                    aria-label="Browse Communities"
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    Browse Communities
                  </Link>
                </li>
                <li>
                  <Link
                    to="/privacy-policy"
                    className="mr-4 hover:text-gray-300"
                    aria-label="Privacy Policy"
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <div className="text-lg font-bold my-2 mt-6">Resources</div>
              <ul className="flex flex-wrap">
                <li>
                  <Link
                    to="/senior-living-guide"
                    className="mr-4 hover:text-gray-300"
                    aria-label="Senior Living Guide"
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    Senior Living Guide
                  </Link>
                </li>
                <li>
                  <Link
                    to="/find-an-advisor"
                    className="mr-4 hover:text-gray-300"
                    aria-label="Real Estate Resources"
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    Real Estate Resources
                  </Link>
                </li>
                <li>
                  <Link
                    to="/legal-and-financial"
                    className="mr-4 hover:text-gray-300"
                    aria-label="Legal & Financial Planning"
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    Legal & Financial Planning
                  </Link>
                </li>
                <li>
                  <Link
                    to="/blog-pages"
                    className="mr-4 hover:text-gray-300"
                    aria-label="Blog"
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    Blog
                  </Link>
                </li>
                <li>
                  <Link
                    to="/types-of-care"
                    className="mr-4 hover:text-gray-300"
                    aria-label="Types of Care"
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    Types of Care
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <div className="text-lg font-bold my-2 mt-6">
                Tools & Interactive Features
              </div>
              <ul className="flex flex-wrap">
                <li>
                  <Link
                    to="/find-an-advisor"
                    className="mr-4 hover:text-gray-300"
                    aria-label="Find a Senior Advisor"
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    Find a Senior Advisor
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/senior-advisor/registerpage"
                    className="mr-4 hover:text-gray-300"
                    aria-label="Join our team"
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    Join Our Team
                  </Link>
                </li>
                <li>
                  <Link
                    to="/claim-a-community"
                    className="mr-4 hover:text-gray-300"
                    aria-label="Claim a Community"
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    Claim a Community
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <div className="text-lg font-bold my-2 mt-6">
                Account Management
              </div>
              <ul className="flex flex-wrap">
                <li>
                  <Link
                    to="/admin/care-champion/login"
                    className="mr-4 hover:text-gray-300"
                    aria-label="User Dashboard"
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    Care Champion Login
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/senior-advisor/login"
                    className="mr-4 hover:text-gray-300"
                    aria-label="Senior Advisor Login"
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    Senior Advisor Login
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/community-champion/login"
                    className="mr-4 hover:text-gray-300"
                    aria-label="Senior Advisor Login"
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    Community Champion Login
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex w-full md:w-1/4">
            <div>
              <div className="flex flex-col lg:flex-row items-center">
                <Link to="/" aria-label="Home">
                  <img
                    src={logo}
                    alt="bluejay logo"
                    className="w-20 h-auto logo__footer rounded my-7"
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  />
                </Link>
                <Link
                  className="btn-secondary"
                  to="/contact"
                  aria-label="Contact Us"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Contact Us
                </Link>
              </div>
              <p className="footer-serving-la font-bold">
                Serving Los Angeles County and the Surrounding Areas
              </p>
            </div>
          </div>
        </div>
        <div className="mt-8 footer-disclosure text-sm">
          <p>
            Information provided on this site is for informational purposes only
            and may not reflect the most current community listings,
            availability, or pricing; please verify details directly with
            individual communities or agents. The content on this site does not
            constitute legal, financial, or medical advice; consult with a
            licensed professional for guidance specific to your needs. Real
            estate agents and senior advisors featured on Blue Jay are
            independent contractors, not employees of BlueJay®, and are not
            supervised or managed by us. BlueJay® is committed to providing
            equal opportunities and does not discriminate based on race, color,
            religion, sex, national origin, disability, or age. We prioritize
            your privacy, and any personal information collected on this site is
            used in accordance with our Privacy Policy and is not shared without
            your consent. © 2025 BlueJay®. All rights reserved. Unauthorized use
            or reproduction of site content is strictly prohibited.
          </p>
          <p className=" text-right">
            &copy; {new Date().getFullYear()} Bluejay. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
