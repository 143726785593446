import { useState, useEffect } from "react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}
const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  const [bodyOverflow, setBodyOverflow] = useState("");

  useEffect(() => {
    if (isOpen) {
      setBodyOverflow(document.body.style.overflow);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isOpen, bodyOverflow, onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <div
        className={`modal ${
          isOpen ? "show" : ""
        } modal-overlay fixed top-0 left-0  z-50`}
      >
        <div className="modal-content w-full md:w-3/5 bg-white p-6 rounded shadow-lg mx-auto my-10">
          {children}
          <button
            onClick={() => {
              document.body.style.overflow = "";
              onClose();
            }}
            className="mt-4 bg-gray-300 px-4 py-2 rounded hover:bg-gray-400 transition duration-300"
          >
            Close
          </button>
        </div>
      </div>
    </>
  );
};

export default Modal;
