import { createContext, useState, FC } from "react";
import {
  AuthContextType,
  AuthState,
  AuthProviderProps,
} from "../types/authTypes";

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [auth, setAuth] = useState<AuthState>({});
  const [persist, setPersist] = useState<boolean>(
    JSON.parse(localStorage.getItem("persist") || "false")
  );

  const isAuthorized = (role: string | string[]): boolean => {
    if (Array.isArray(role)) {
      return role.some((r) => auth.roles?.includes(r)) || false;
    }
    return auth.roles?.includes(role) || false;
  };

  return (
    <AuthContext.Provider
      value={{ auth, setAuth, isAuthorized, persist, setPersist }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
