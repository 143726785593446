import React from "react";
import { useUserContext } from "../../../../../context/UserContext"; // Import the UserContext for task creator's name
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComments,
  faClock,
  faMailBulk,
  faPhone,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";

type Task = {
  name: string;
  type:
    | "follow_up"
    | "call"
    | "listing_appointment"
    | "email"
    | "text"
    | "tour"
    | "welfare_check";
  dueDate: string;
  dueTime: string;
};

interface TaskCardProps {
  task: Task;
  onTaskComplete: (taskName: string) => void; // Add this prop to handle task completion
  onClick?: () => void;
  key?: string;
}

const TaskCard: React.FC<TaskCardProps> = ({
  task,
  onTaskComplete,
  onClick,
  key,
}) => {
  const user = useUserContext(); // Accessing the user who created the task

  const taskIcons: { [key: string]: any } = {
    follow_up: faComments,
    call: faPhone,
    listing_appointment: faCalendar,
    email: faMailBulk,
    text: faComments,
    tour: faComments,
    welfare_check: faComments,
  };

  const getBackgroundColor = () => {
    const now = new Date();
    const taskDueDate = new Date(`${task.dueDate}T${task.dueTime}`);
    const timeDifference = taskDueDate.getTime() - now.getTime();
    const hoursUntilDue = timeDifference / (1000 * 60 * 60);

    if (hoursUntilDue < 0) {
      return "bg-red-100 border-red-400";
    } else if (hoursUntilDue <= 24) {
      return "bg-yellow-100 border-yellow-400";
    } else {
      return "bg-white border-gray-200";
    }
  };

  return (
    <div
      className={`hover:cursor-pointer p-4 rounded-lg shadow-sm border flex items-start space-x-3 ${getBackgroundColor()}`}
    >
      {/* Checkbox to mark task as complete */}
      <input
        type="checkbox"
        className="mr-3"
        onChange={() => onTaskComplete(task.name)} // Call the handler when task is completed
      />
      <div onClick={onClick} className="hover:cursor-pointer">
        {/* Task Icon */}
        <div className="flex-shrink-0">
          <FontAwesomeIcon
            icon={taskIcons[task.type]}
            className="text-primary text-lg"
          />
        </div>

        {/* Task Details */}
        <div className="flex-1 min-w-0">
          <p className="text-sm font-medium text-gray-900">{task.name}</p>
          <p className="text-xs text-gray-500 flex items-center space-x-1">
            <FontAwesomeIcon icon={faClock} className="text-gray-400" />
            <span>{task.dueDate}</span>
          </p>
        </div>

        {/* Due Time */}
        <div className="text-xs text-gray-500">{task.dueTime}</div>
      </div>
    </div>
  );
};

export default TaskCard;
