import React from "react";

interface DisplayLeadProps {
  lead: {
    fname: string;
    lname: string;
    phone: string;
    email: string;
    address1: string;
  };
}

const DisplayLead: React.FC<DisplayLeadProps> = ({ lead }) => {
  return (
    <div className="p-6 bg-white shadow-lg rounded-md w-full">
      <div className="flex items-center">
        <img
          className="w-24 h-24 rounded-full"
          src={"https://via.placeholder.com/150"}
          alt="User Avatar"
        />
        {lead.fname && <span className="font-semibold m-2">{lead.fname}</span>}
        {lead.lname && <span className="font-semibold m-2">{lead.lname}</span>}
      </div>
      <div className="m-3">
        {lead.phone && <p className="mt-2">{lead.phone}</p>}
        {lead.email && <p className="mt-2">{lead.email}</p>}
        {lead.address1 && <p className="mt-2">{lead.address1}</p>}
      </div>
    </div>
  );
};

export default DisplayLead;
