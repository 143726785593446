import React from "react";
import Photo from "./advisorInfo/Photo";
import Name from "./advisorInfo/Name";
import LicenseNumber from "./advisorInfo/LicenseNumber";
import DirectPhone from "./advisorInfo/DirectPhone";
import CellPhone from "./advisorInfo/CellPhone";
import EmailButton from "./advisorInfo/EmailButton";
import CredentialsList from "./advisorInfo/CredentialsList";
import ServicesList from "./advisorInfo/ServicesList";
import AwardsRecognitions from "./advisorInfo/AwardsRecognitions";
import ClientTestimonials from "./advisorInfo/ClientTestimonials";
import YearsInBusiness from "./advisorInfo/YearsInBusiness";
import AreasServed from "./advisorInfo/AreasServed";
import FollowMeLinks from "./advisorInfo/FollowMeLinks";
import { Advisor } from "../../types/advisor";

interface SeniorAdvisorCardProps {
  advisor: Advisor | undefined;
}

const SeniorAdvisorCard: React.FC<SeniorAdvisorCardProps> = ({ advisor }) => {
  return (
    <div className="mx-auto advisor-card border p-4 rounded-lg">
      {/* Conditionally render each component if the data is provided */}
      {/* {advisor.photo && <Photo src={advisor.photo} />} */}
      <img src={"https://via.placeholder.com/150"} alt="" />
      {advisor?.fname && <Name fname={advisor.fname} lname={advisor.lname} />}
      {advisor?.lsNo && (
        <div className="flex flex-col align-middle md:flex-row">
          <h2 className="text-xl font-semibold mb-2">Senior Advisor</h2>

          {advisor.lsNo && (
            <span className="hidden md:inline font-bold mb-2 ml-2 mr-2">
              {" | "}
            </span>
          )}
          {advisor.lsNo && <LicenseNumber license={advisor.lsNo} />}
        </div>
      )}
      {advisor?.mobile && <DirectPhone phone={advisor.mobile} />}
      {advisor?.mobile && <CellPhone phone={advisor.mobile} />}
      {advisor?.email && <EmailButton email={advisor.email} />}
      {/* {advisor.credentials && advisor.credentials.length > 0 && (
        <CredentialsList credentials={advisor.credentials} />
      )}
      {advisor.services && advisor.services.length > 0 && (
        <ServicesList services={advisor.services} />
      )}
      {advisor.awards && advisor.awards.length > 0 && (
        <AwardsRecognitions awards={advisor.awards} />
      )}
      {advisor.testimonials && advisor.testimonials.length > 0 && (
        <ClientTestimonials testimonials={advisor.testimonials} />
      )}
      {advisor.yearsInBusiness !== undefined && (
        <YearsInBusiness years={advisor.yearsInBusiness} />
      )}
      {advisor.areasServed && advisor.areasServed.length > 0 && (
        <AreasServed areas={advisor.areasServed} />
      )}
      {advisor.followMeLinks && advisor.followMeLinks.length > 0 && (
        <FollowMeLinks links={advisor.followMeLinks} />
      )} */}
    </div>
  );
};

export default SeniorAdvisorCard;
