import { fetchData } from "../ClientTransitionSupport";
import { ColumnDef } from "@tanstack/react-table";
import { MoreHorizontal, ArrowUpDown } from "lucide-react";
import { Checkbox } from "../../../../components/ui/checkbox";
import { Button } from "../../../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../../components/ui/dropdown-menu";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../components/ui/dialog";
import EditVendor from "./EditVendor";

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.
export type Vendor = {
  id: string;
  vendor: string;
  contact: string;
  title: string;
  email: string;
  telephone: string;
  additional: string;
};

export const columns: ColumnDef<Vendor>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "vendor",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Vendor
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
  },
  {
    accessorKey: "contact",
    header: "Contact",
  },
  {
    accessorKey: "title",
    header: "Title",
  },
  {
    accessorKey: "email",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Email
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
  },
  {
    accessorKey: "telephone",
    header: "Telephone",
  },
  {
    accessorKey: "additional",
    header: "Additional",
  },

  {
    id: "actions",
    enableHiding: false,

    cell: ({ row }) => {
      const vendor = row.original;

      const handleEdit = () => {
        // Edit the vendor
        alert(`Editing vendor: ${vendor.contact}`);
        fetchData();
      };
      const handleDelete = () => {
        // Delete the vendor
        alert(`Deleting vendor: ${vendor.contact}`);
        if (
          window.confirm(`Are you sure you want to delete ${vendor.vendor}?`)
        ) {
          // Call an API or update state to delete the vendor
          console.log(`Deleted vendor: ${vendor.vendor}`);
        }
      };

      return (
        <>
          <Dialog>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <MoreHorizontal className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                <DropdownMenuItem
                  onClick={() => navigator.clipboard.writeText(vendor.email)}
                >
                  Copy vendor email
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DialogTrigger asChild>
                  <DropdownMenuItem onClick={handleEdit}>Edit</DropdownMenuItem>
                </DialogTrigger>

                <DropdownMenuItem onClick={handleDelete}>
                  Delete
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Edit {vendor.vendor}</DialogTitle>
                <DialogDescription>
                  Fill in the form below to add a new vendor.
                </DialogDescription>
              </DialogHeader>
              <EditVendor onEditVendor={handleEdit} />
            </DialogContent>
          </Dialog>
        </>
      );
    },
  },
];
