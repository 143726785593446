import React from "react";
import { useParams } from "react-router-dom";
import Name from "./advisorInfo/Name.tsx";
import Summary from "./advisorInfo/Summary.tsx";
import SeniorAdvisorCard from "./SeniorAdvisorCard.tsx";
import { Advisor } from "../../types/advisor";

const summary = `John Doe is a Senior Advisor with 15 years of experience in real estate. He specializes in helping seniors transition to new living arrangements and provides support for estate planning.

Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestiae voluptatum quaerat aliquam doloremque facilis tempore dolore dolorum ducimus, iste consequatur quidem rem vel aut eveniet delectus, minus perferendis architecto saepe!

Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestiae voluptatum quaerat aliquam doloremque facilis tempore dolore dolorum ducimus, iste consequatur quidem rem vel aut eveniet delectus, minus perferendis architecto saepe!

Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sapiente officiis necessitatibus a modi fugiat laudantium maxime dolorum, delectus enim doloribus non suscipit minima blanditiis laboriosam inventore similique tenetur et ipsa est error recusandae quis? Omnis, ut laborum facilis quis minus necessitatibus recusandae dolore repellat qui.
  `;

interface SeniorAdvisorProps {
  advisor: Advisor | undefined;
}

const SeniorAdvisor: React.FC<SeniorAdvisorProps> = ({ advisor }) => {
  return (
    <div className="flex flex-col md:flex-row p-4 bg-white shadow-md rounded-lg">
      <div className="p-3 border-2 w-full">
        <SeniorAdvisorCard advisor={advisor} />
      </div>
      {/* summary of advisor */}
      <div className="p-4">
        <Name fname={advisor?.fname} lname={advisor?.lname} />
        <h2 className="text-xl font-semibold mb-2">Senior Advisor</h2>

        <Summary summary={summary} />
      </div>
    </div>
  );
};

export default SeniorAdvisor;
