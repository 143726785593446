// import CommunityComponent from "../../components/community/CommunityComponent";
import { useParams } from "react-router-dom";

const Community = () => {
  const { dynamicRoute } = useParams();

  return (
    <div>
      {dynamicRoute?.toUpperCase()}
      {/* <CommunityComponent communityId={dynamicRoute || "default-community"} /> */}
    </div>
  );
};

export default Community;
