import React, { useEffect, useState } from "react";
import axiosInstance from "../components/api/axiosInstance.tsx";
import Callout from "../components/utils/Callout.tsx";
import VerticalCarousel from "../components/utils/VerticalCarousel.tsx";
import FreeServices from "./HeaderPages/FreeServices.tsx";
import WhatWeOffer from "./HeaderPages/WhatWeOffer.tsx";
import Modal from "../components/utils/Modal.tsx";
import HowItWorksHome from "./HeaderPages/HowItWorksHome.tsx";

// interface CommunityData {
//   id: number;
//   name: string;
//   address1: string;
//   address2: string;
//   city: string;
//   state: string;
//   zip: string;
//   phone: string;
//   stateLicense: string;
//   description: string;
//   latitude: string;
//   longitude: string;
//   website: string;
//   rating: number;
//   ratingCount: number;
//   active: boolean;
// }

const SearchResults: React.FC = () => {
  // const [showModal, setShowModal] = useState<boolean>(true);

  // const communityDatas = {
  //   imageUrl: "https://via.placeholder.com/400",
  //   communityDetailsUrl: "/community-details",
  //   title: "Community Name",
  //   contactName: "John Doe",
  //   contactTitle: "Community Manager",
  //   messageUrl: "/community-details/message",
  //   email: "contact@community.com",
  //   address: "123 Community St, Los Angeles, CA, 90065",
  //   reviews: [
  //     { id: 1, name: "John Doe", content: "Great community!", rating: 8.4 },
  //     {
  //       id: 2,
  //       name: "Jill Smith",
  //       content: "Very friendly people.",
  //       rating: 9.2,
  //     },
  //   ],
  // };
  const calloutData = {
    title:
      "Discover Senior Care Solutions with Zero Costs and Zero Referral Fees!",
    content:
      "Search for the perfect community absolutely free for you and the community. We're dedicated to providing cost-free introductions to the best senior care options available. As the first and only senior care website with no affiliations, we ensure you're connected directly to the finest communities that meet your needs, with no hidden fees or conflicts of interest.",
  };

  // const [communityData, setCommunityData] = useState<CommunityData[]>([]);

  // useEffect(() => {
  //   axiosInstance
  //     .post("/Communities")
  //     .then((response) => {
  //       setCommunityData(response.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching community data:", error);
  //     });
  // }, []);

  // if (!communityData) {
  //   return <div>Loading...</div>;
  // }

  return (
    <>
      {/* {showModal && ( */}
      {/* <Modal onClose={() => setShowModal(false)} isOpen={showModal}> */}
      <Callout title={calloutData.title} content={calloutData.content} />
      {/* </Modal> */}
      {/* )} */}
      <div className="container z-50"></div>
      <div className="">
        <div className="flex flex-col sm:flex-row search-results space-y-8 mb-9">
          {/* <FilterComponent /> */}
          <div className="w-full ">
            <VerticalCarousel />
            <WhatWeOffer />
            <HowItWorksHome />
            <FreeServices />
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchResults;
