// import LeadTrackerTable from "../../../components/admin/InfoCard/Components/Lead/LeadTrackerTable";
import LeadTracker from "./LeadTracker";
function Leads() {
  return (
    <div>
      <LeadTracker />
      {/* <LeadTrackerTable /> */}
    </div>
  );
}

export default Leads;
