import Toolbar from "./Toolbar";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Heading from "@tiptap/extension-heading";
import List from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import Image from "@tiptap/extension-image";
import axiosInstance from "../../../../components/api/axiosInstance";

interface MyEditorProps {
  onChange: (content: string) => void;
  content: string;
}

function uploadImage(file: any) {
  const data = new FormData();
  data.append("file", file);
  return axiosInstance.post("/documents/image/upload", data);
}

const MyEditor: React.FC<MyEditorProps> = ({ content, onChange }) => {
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        // options.
        heading: {
          levels: [1, 2, 3],
        },
      }),
      Image,
      Heading.configure({
        HTMLAttributes: {
          class: "text-xl font-bold",
          levels: [2],
        },
      }),
      List.configure({
        HTMLAttributes: {
          class: "list-disc pl-4",
        },
      }),
      OrderedList.configure({
        HTMLAttributes: {
          class: "list-decimal pl-4",
        },
      }),
    ],
    content: `
    <p>Hello World!</p>
    <img src="https://via.placeholder.com/150" />
  `,
    editorProps: {
      attributes: {
        class: "rounded-md border min-h-[150px] border-input p-4",
      },
      handleDrop: function (view, event, slice, moved) {
        if (
          !moved &&
          event.dataTransfer &&
          event.dataTransfer.files &&
          event.dataTransfer.files[0]
        ) {
          // if dropping external files
          let file = event.dataTransfer.files[0]; // the dropped file
          let filesize = Number((file.size / 1024 / 1024).toFixed(4)); // get the filesize in MB
          if (
            (file.type === "image/jpeg" || file.type === "image/png") &&
            filesize < 10
          ) {
            // check valid image type under 10MB
            // check the dimensions
            let _URL = window.URL || window.webkitURL;
            let img = new Image(); /* global Image */
            img.src = _URL.createObjectURL(file);
            img.onload = function () {
              if (this.width > 5000 || this.height > 5000) {
                window.alert(
                  "Your images need to be less than 5000 pixels in height and width."
                ); // display alert
              } else {
                // valid image so upload to server
                // uploadImage will be your function to upload the image to the server or s3 bucket somewhere
                uploadImage(file)
                  .then(function (response) {
                    // response is the image url for where it has been saved
                    // pre-load the image before responding so loading indicators can stay
                    // and swaps out smoothly when image is ready
                    let image = new Image();
                    image.src = response;
                    image.onload = function () {
                      // place the now uploaded image in the editor where it was dropped
                      const { schema } = view.state;
                      const coordinates = view.posAtCoords({
                        left: event.clientX,
                        top: event.clientY,
                      });
                      const node = schema.nodes.image.create({ src: response }); // creates the image element

                      if (coordinates) {
                        const transaction = view.state.tr.insert(
                          coordinates.pos,
                          node
                        ); // places it in the correct position
                        return view.dispatch(transaction);
                      }
                    };
                  })
                  .catch(function (error) {
                    if (error) {
                      window.alert(
                        "There was a problem uploading your image, please try again."
                      );
                    }
                  });
              }
            };
          } else {
            window.alert(
              "Images need to be in jpg or png format and less than 10mb in size."
            );
          }
          return true; // handled
        }
        return false; // not handled use default behaviour
      },
    },
    onUpdate({ editor }) {
      onChange(editor.getHTML());
    },
  });

  return (
    <div>
      <Toolbar editor={editor} />
      <EditorContent editor={editor} />
    </div>
  );
};

export default MyEditor;
