import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import logo from "../../assets/Bluejay logo.png"; // Importing logo image
import RoleSwitcher from "../admin/RoleSwitcher";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

interface MenuItem {
  to: string;
  label: string | React.ReactElement;
  index: number;
  ariaLabel: string;
  dropdown?: {
    to: string;
    label: string;
    icon: IconDefinition;
  }[];
}
interface AdminHeaderProps {
  menuItems: MenuItem[];
}

const AdminHeader: React.FC<AdminHeaderProps> = ({ menuItems }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false); // Modal state
  const [isSelected, setIsSelected] = useState<number | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);

  const location = useLocation();

  const handleLinkClick = (index: number) => {
    setIsSelected(index);
  };

  const handleMouseEnter = (index: number) => {
    setDropdownOpen(index);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(null);
  };

  // modal control
  const handleSignUpClick = () => {
    setIsModalOpen(true); // Open modal on sign-up click
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close modal
  };

  return (
    <div className=" flex bg-secondary self-center">
      <div className="container flex items-center justify-between py-7">
        <div className="flex w-1/2 items-center">
          <Link
            to="/"
            className="flex items-center" // Centering the logo and role switcher
            aria-label="home logo button"
          >
            <img src={logo} alt="BlueJay Logo" className="w-12 h-auto" />
          </Link>
          <RoleSwitcher />
        </div>
        <div>
          {menuItems.map((item) => (
            <div
              key={item.index}
              onMouseEnter={() => handleMouseEnter(item.index)}
              onMouseLeave={handleMouseLeave}
              className="relative inline-block"
            >
              <Link
                to={item.to}
                onClick={() =>
                  item.index === 1
                    ? handleSignUpClick()
                    : handleLinkClick(item.index)
                } // Trigger modal for Sign Up
                className={`block bg-transparent p-3  rounded mt-4 md:inline-block md:mt-0 text-action-100 mr-4 hover:bg-tertiary-500 hover:text-accent-100 
                ${
                  isSelected === item.index || location.pathname === item.to
                    ? "text-primary"
                    : "text-accent"
                }`}
                aria-label={item.ariaLabel}
              >
                {item.label}
              </Link>

              {/* Dropdown Menu */}
              {item.dropdown && dropdownOpen === item.index && (
                <div
                  className="absolute right-0 mt-0 w-48 bg-white border rounded shadow-lg z-10"
                  onMouseEnter={() => handleMouseEnter(item.index)}
                  onMouseLeave={handleMouseLeave}
                >
                  {item.dropdown.map((subItem, subIndex) => (
                    <Link
                      key={subIndex}
                      to={subItem.to}
                      className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-200"
                    >
                      {/* Icon on the left */}
                      <FontAwesomeIcon
                        icon={subItem.icon}
                        className="w-5 h-5 text-gray-600 mr-2"
                      />
                      {subItem.label}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;
