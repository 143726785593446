import { useEffect, useState } from "react";

interface StageProps {
  stage: number;
}

const Stage: React.FC<StageProps> = ({ stage }) => {
  const [selectedStage, setSelectedStage] = useState(stage);
  const enumValues = {
    1: "Hot Seller (1-3 months)",
    2: "Warm Seller (6-12 months)",
    3: "Cold Seller (1-3 years)",
    4: "Lease Client",
    5: "Aging in Place",
    6: "Legacy Planning",
  };
  useEffect(() => {
    setSelectedStage(stage);
  }, [stage]);

  return (
    <div className="border p-4 rounded-md">
      <h3 className="font-bold mb-2">Stage Lead</h3>
      <select
        className="input-field"
        value={selectedStage}
        onChange={(e) => setSelectedStage(Number(e.target.value))}
      >
        {Object.entries(enumValues).map(([key, label]) => (
          <option key={key} value={key}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Stage;
