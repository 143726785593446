import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import axiosInstance from "../../../../components/api/axiosInstance.tsx";
import MyEditor from "./MyEditor.tsx";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../components/ui/form";
import { Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button.tsx";
import { Checkbox } from "../../../../components/ui/checkbox.tsx";
import { toast } from "react-toastify";

// Define validation schema using Zod
const blogPostSchema = z.object({
  title: z
    .string()
    .min(3, { message: "Title must be at least 3 characters long" }),
  body: z
    .string()
    .min(10, { message: "Body must be at least 10 characters long" })
    .trim(),
  publishedOn: z.string().nonempty({ message: "Published date is required" }),
  visible: z.boolean(),
  author: z
    .string()
    .min(2, { message: "Author name must be at least 2 characters long" }),
});

type BlogPostFormValues = z.infer<typeof blogPostSchema>;

const BlogPost: React.FC = () => {
  const navigate = useNavigate();
  const form = useForm<BlogPostFormValues>({
    mode: "onChange",
    resolver: zodResolver(blogPostSchema),
    defaultValues: {
      title: "",
      body: "",
      publishedOn: new Date().toISOString(),
      visible: false,
      author: "",
    },
  });

  useEffect(() => {
    // Fetch blog post data (e.g., for editing)
    axiosInstance.get("/blogs/1").then((response) => {
      const data = response.data;
      form.reset({
        title: data.title,
        body: data.body,
        publishedOn: data.publishedOn,
        visible: data.visible,
        author: data.author,
      });
    });
  }, [form.reset]);

  const onSubmit = (data: BlogPostFormValues) => {
    // (values: z.infer<typeof blogPostSchema>) the way that video suggests
    console.log("Publishing blog post:", data);
    axiosInstance
      .post("/blogs", data)
      .then((response) => {
        toast.success("Blog post published successfully");
        console.log("Blog post published:", response.data);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      })
      .catch((error) => {
        toast.error("Failed to publish blog post");
        console.error("Error publishing blog post:", error);
      });
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white shadow-md rounded-lg ">
      <h2 className="text-2xl font-semibold mb-4 ">Publish Blog Post</h2>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="title"
            render={({ field }) => (
              <FormItem>
                <FormLabel htmlFor="title">Title</FormLabel>
                <FormControl>
                  <Input {...field} id="title" placeholder="Place Title Here" />
                </FormControl>
                <FormMessage>
                  {form.formState.errors.title?.message}
                </FormMessage>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="author"
            render={({ field }) => (
              <FormItem>
                <FormLabel htmlFor="author">Author</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    id="author"
                    placeholder="Place Author Here"
                  />
                </FormControl>
                <FormMessage>
                  {form.formState.errors.author?.message}
                </FormMessage>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="body"
            render={({ field }) => (
              <FormItem>
                <FormLabel htmlFor="body">Body</FormLabel>
                <FormControl>
                  <MyEditor
                    // content={field.value}
                    // onChange={(value: string) => form.setValue("body", value)}
                    content={field.name}
                    onChange={field.onChange}
                  />
                </FormControl>
                <FormMessage>{form.formState.errors.body?.message}</FormMessage>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="publishedOn"
            render={({ field }) => (
              <FormItem>
                <FormLabel htmlFor="publishedOn">Published On Date</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    id="publishedOn"
                    placeholder="Place Author Here"
                    type="date"
                    defaultValue={new Date().toDateString()}
                    // disabled={true}
                  />
                </FormControl>
                <FormMessage>
                  {form.formState.errors.publishedOn?.message}
                </FormMessage>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="visible"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                <FormLabel htmlFor="visible">Visible</FormLabel>
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <FormMessage>
                  {form.formState.errors.publishedOn?.message}
                </FormMessage>
              </FormItem>
            )}
          />

          {/* Rich Text Editor (optional) */}
          {/* <Controller
            name="body"
            control={form.control}
            render={({ field }) => (
              <MyEditor
                content={field.value}
                onChange={(value: string) => form.setValue("body", value)}
              />
            )}
          /> */}

          {/* Visible Field */}
          {/* <div className="mb-4">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              {...register("visible")}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <span className="ml-2 text-gray-700">Visible</span>
          </label>
        </div> */}

          {/* Submit Button */}
          <Button
            type="submit"
            className="w-full bg-primary text-white py-2 px-4 rounded-lg hover:bg-primary-600 focus:outline-none"
          >
            Publish
          </Button>
        </form>
      </Form>
    </div>
  );
};

export default BlogPost;
