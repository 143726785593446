import { useState, useEffect } from "react";
import Stage from "../../Helper/LeadComponents/Stage.tsx";
import Tags from "../../Helper/LeadComponents/Tags.tsx";
import CustomFields from "../../Helper/LeadComponents/CustomFields.tsx";
import TaskTracker from "../Task/TaskTracker.tsx";
import AppointmentTracker from "../Appointment/AppointmentTracker.tsx";
import LeadTracker from "../Lead/LeadTracker.tsx";
import DisplayLead from "../Lead/DisplayLead.tsx";
import Pagination from "../Lead/Pagination.tsx";
import axiosInstance from "../../../../api/axiosInstance.tsx";
import { Lead2 as LeadData } from "../../../../../types/lead.ts";
import { useUserContext } from "../../../../../context/UserContext.tsx";

const SeniorLeadCard: React.FC = () => {
  const { user } = useUserContext();
  const [leadDataArr, setLeadDataArr] = useState<LeadData[]>([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    axiosInstance.get(`/Leads/agent/${user.id}`).then((response) => {
      console.log("lead data", response.data);
      setLeadDataArr(response.data);
    });
  }, []);

  const totalLeads = leadDataArr.length;

  const handlePrevious = () => {
    setCurrentPage((prev) => (prev > 0 ? prev - 1 : 0));
  };

  const handleNext = () => {
    setCurrentPage((prev) =>
      prev < totalLeads - 1 ? prev + 1 : totalLeads - 1
    );
  };

  const currentLead = leadDataArr[currentPage];

  return (
    <div className="p-6 bg-white shadow-lg rounded-md w-full">
      <div className="flex items-center justify-between px-4 mb-4">
        <h1 className="text-2xl font-bold">Senior Lead Card</h1>
        {/* Pagination */}
        <Pagination
          currentPage={currentPage}
          totalItems={totalLeads}
          onPrevious={handlePrevious}
          onNext={handleNext}
        />
      </div>

      {currentLead ? (
        <div className="flex flex-col md:flex-row space-x-4">
          {/* Left column */}
          <div className="w-full md:w-1/4 space-y-4 border p-4 rounded-md mb-4">
            {/* Pass the current lead to DisplayLead */}
            <DisplayLead
              lead={{
                fname: currentLead.fname,
                lname: currentLead.lname,
                phone: currentLead.phone,
                email: currentLead.email,
                address1: currentLead.address1,
              }}
            />
            {/* Pass specific properties to individual components */}
            <Stage stage={currentLead.leadStatus} />
            <Tags leadTags={currentLead.leadTags} />
            <CustomFields customFields={currentLead.leadCustomFields} />
          </div>
          <div className="w-full md:w-1/2">
            <LeadTracker />
          </div>

          <div className="w-full md:w-1/4 space-y-4">
            <div className="border p-4 rounded-lg shadow-md">
              <TaskTracker />
              <AppointmentTracker />
            </div>
          </div>
        </div>
      ) : (
        <p>Loading leads...</p>
      )}

      <button className="btn-danger">Deactivate Lead</button>
    </div>
  );
};

export default SeniorLeadCard;
