import { Controller } from "react-hook-form";

type MyBooleanInputProps = {
  control: any; // Replace with your control type if necessary
  name: string;
};

const MyBooleanInput: React.FC<MyBooleanInputProps> = ({ control, name }) => {
  return (
    <div className="flex flex-col justify-center">
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <>
            <label>
              Yes
              <input
                className="ml-2"
                type="radio"
                onBlur={onBlur}
                onChange={() => onChange(true)} // Send `true` value
                checked={value === true}
                ref={ref}
              />
            </label>
            <label>
              No
              <input
                className="ml-2"
                type="radio"
                onBlur={onBlur}
                onChange={() => onChange(false)} // Send `false` value
                checked={value === false}
                ref={ref}
              />
            </label>
          </>
        )}
      />
    </div>
  );
};

export default MyBooleanInput;
