import React, { useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import axiosInstance from "../../../components/api/axiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";

type LoginFormData = {
  username: string;
  password: string;
};

const Login: React.FC = () => {
  const { setAuth, persist, setPersist } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormData>({ mode: "onTouched" });

  const location = useLocation();
  const pathParts = location.pathname.split("/");
  const role = pathParts[pathParts.length - 2];

  const onSubmit: SubmitHandler<LoginFormData> = async (data) => {
    try {
      const response = await axiosInstance.post("/Authenticate/login", data);
      toast.success("Login successful!");
      console.log("Login successful:", response.data);
      const accessToken = response?.data?.token;
      const roles = ["1000"];
      setAuth({ accessToken, roles });
      setPersist(true);
    } catch (error) {
      toast.error("Login failed. Please try again.");
      console.error("Login failed:", error);
    }
  };

  useEffect(() => {
    localStorage.setItem("persist", JSON.stringify(persist));
  }, [persist]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-center text-primary">
          Community Champion Login
        </h2>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Username
            </label>
            <input
              type="text"
              {...register("username", { required: "Username is required" })}
              className="input-field"
            />
            {errors.username && (
              <p className="text-red-500 text-sm">{errors.username.message}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <input
              type="password"
              {...register("password", { required: "Password is required" })}
              className="input-field"
            />
            {errors.password && (
              <p className="text-red-500 text-sm">{errors.password.message}</p>
            )}
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-primary text-white rounded-md"
          >
            Login
          </button>
        </form>
        <div className="">
          <p className="mb-4 text-sm text-primary">Don't have an account?</p>

          <Link
            to={`/admin/${role}/register`}
            className="btn-primary"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            Create Account
          </Link>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </div>
  );
};

export default Login;
