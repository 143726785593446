import { useState } from "react";
import CreateNote from "../../Helper/Communication/CreateNote"; // Import the CreateNote component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbTack, faEnvelope } from "@fortawesome/free-solid-svg-icons"; // Import icons
import MessageController from "../MessageController";

type TimelineItem = {
  date: string;
  content: string;
  hasReadMore: boolean;
  linkText?: string;
  type: "note" | "email"; // Added type to distinguish between note and email
};

const initialTimelineData: TimelineItem[] = [
  {
    date: "Oct 15, 2024",
    content: "Welcome to BlueJay! View the welcome email.",
    hasReadMore: true,
    linkText: "View Email",
    type: "email", // Type added
  },
  {
    date: "Oct 16, 2024",
    content:
      "Spoke with the older client Chris about his mother's recovery." +
      "He is happy with the progress." +
      "He is looking forward to the next visit." +
      "He is happy with the progress." +
      "He is looking forward to the next visit." +
      "Spoke with the older client Chris about his mother's recovery." +
      "He is happy with the progress." +
      "He is looking forward to the next visit." +
      "He is happy with the progress." +
      "He is looking forward to the next visit." +
      "Spoke with the older client Chris about his mother's recovery." +
      "He is happy with the progress." +
      "He is looking forward to the next visit." +
      "He is happy with the progress." +
      "He is looking forward to the next visit.",
    hasReadMore: true,
    linkText: "Read More",
    type: "note", // Type added
  },
];

function LeadTracker() {
  const [timelineData, setTimelineData] =
    useState<TimelineItem[]>(initialTimelineData);
  const [expandedItems, setExpandedItems] = useState<number[]>([]);
  const [visibleCount, setVisibleCount] = useState(3); // Show 3 items initially

  const toggleExpand = (index: number) => {
    setExpandedItems((prevExpandedItems) =>
      prevExpandedItems.includes(index)
        ? prevExpandedItems.filter((item) => item !== index)
        : [...prevExpandedItems, index]
    );
  };

  const showMoreMessages = () => {
    setVisibleCount((prevCount) => prevCount + 3); // Load 3 more items
  };

  const handleAddNote = (noteContent: string) => {
    const newNote: TimelineItem = {
      date: new Date().toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      }),
      content: noteContent,
      hasReadMore: false,
      type: "note",
    };

    setTimelineData((prevData) => [newNote, ...prevData]); // Add new note to the top
  };

  return (
    <div className="border p-4 rounded-md mb-4">
      <MessageController forCreateNote={handleAddNote} />
      {/* <CreateNote onAddNote={handleAddNote} /> Create Note Component */}
      <p className="mb-4 font-bold">Timeline</p>
      <div className="relative">
        {/* Timeline Vertical Line */}
        {/* <div className="absolute left-1 top-0 h-full w-0.5 bg-gray-300"></div> */}

        {timelineData.slice(0, visibleCount).map((item, index) => {
          const isExpanded = expandedItems.includes(index);
          const contentToShow = isExpanded
            ? item.content
            : item.content.slice(0, 100) + (item.hasReadMore ? "..." : "");

          return (
            <div key={index} className="time-item pl-10 mb-6 relative">
              {/* Timeline Dot or Icon */}
              <div className="time-item-icon">
                {item.type === "note" ? (
                  <FontAwesomeIcon
                    icon={faThumbTack}
                    className="text-primary"
                  />
                ) : (
                  <FontAwesomeIcon icon={faEnvelope} className="text-primary" />
                )}
              </div>

              <div className="border p-4 rounded-md shadow-sm bg-white">
                <p className="text-gray-500 font-bold">{item.date}</p>
                <div className="space-y-2">
                  <div>
                    <p className="text-gray-800">
                      {contentToShow}{" "}
                      {item.hasReadMore && (
                        <a
                          href="#"
                          className="text-primary font-semibold hover:text-gray-500"
                          onClick={() => toggleExpand(index)}
                        >
                          {isExpanded ? "Show Less" : item.linkText}
                        </a>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        {/* Show More Messages Button */}
        {visibleCount < timelineData.length ? (
          <button onClick={showMoreMessages} className="btn-tertiary">
            {timelineData.length - visibleCount} more messages in thread
          </button>
        ) : (
          <button
            onClick={() => setVisibleCount(3)} // Collapse to show only the first 3 items
            className="block mx-auto px-4 py-2 mt-4 text-white bg-primary rounded hover:bg-secondary focus:outline-none focus:ring focus:ring-indigo-200"
          >
            Show less
          </button>
        )}
      </div>
    </div>
  );
}

export default LeadTracker;
