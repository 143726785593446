import { useForm, SubmitHandler } from "react-hook-form";
import { Button } from "../../../../components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../components/ui/form";
import { Input } from "../../../../components/ui/input";

import axiosInstance from "../../../../components/api/axiosInstance";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type FormData = {
  id: number;
  vendor: string;
  contact: string;
  title: string;
  email: string;
  telephone: string;
  additional: string;
};

const AddNewVendor = () => {
  const form = useForm<FormData>({ mode: "onTouched" });

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      const response = await axiosInstance.post(
        "/Authenticate/registersenioradvisor",
        data
      );
      toast.success("Add Vendor successful!");
      console.log("Add Vendor successful:", response.data);
    } catch (error) {
      console.log("Add Vendor failed:", error);
      toast.error("Add Vendor. Please try again.");
    }
  };
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="vendor"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Vendor Name</FormLabel>
              <FormControl>
                <Input placeholder="vendor name" {...field} />
              </FormControl>
              <FormDescription>
                This is your public vendor name.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="contact"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Contact Name</FormLabel>
              <FormControl>
                <Input placeholder="contact name" {...field} />
              </FormControl>
              {/* <FormDescription>
                This is your point of contact name.
              </FormDescription> */}
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Title</FormLabel>
              <FormControl>
                <Input placeholder="Title" {...field} />
              </FormControl>
              {/* <FormDescription>This is your public title.</FormDescription> */}
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input placeholder="Email" {...field} />
              </FormControl>
              {/* <FormDescription>This is your email.</FormDescription> */}
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="telephone"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Telephone</FormLabel>
              <FormControl>
                <Input placeholder="Telephone" {...field} />
              </FormControl>
              {/* <FormDescription>This is your public telephone.</FormDescription> */}
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="additional"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Additional</FormLabel>
              <FormControl>
                <Input placeholder="Additional" {...field} />
              </FormControl>
              <FormDescription>
                This is for additional notes such as last contact or where you
                met, etc.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit">Submit</Button>
      </form>
    </Form>
  );
};

export default AddNewVendor;
