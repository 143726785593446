import { type Editor } from "@tiptap/react";
import {
  Bold,
  Strikethrough,
  Italic,
  List,
  ListOrdered,
  Heading2,
  Heading3,
} from "lucide-react";
import { Toggle } from "../../../../components/ui/toggle";

type Props = {
  editor?: Editor | null;
};

const Toolbar = ({ editor }: Props) => {
  return (
    <div className="flex justify-between items-center bg-gray-100 p-2">
      <div className="flex space-x-2">
        <Toggle
          size="sm"
          pressed={editor?.isActive("bold")}
          onPressedChange={() => editor?.chain().focus().toggleBold().run()}
          className="p-2 rounded-md hover:bg-gray-200"
        >
          <Bold size={24} />
        </Toggle>
        <Toggle
          size="sm"
          pressed={editor?.isActive("italic")}
          onPressedChange={() => editor?.chain().focus().toggleItalic().run()}
          className="p-2 rounded-md hover:bg-gray-200"
        >
          <Italic size={24} />
        </Toggle>
        <Toggle
          size="sm"
          pressed={editor?.isActive("strike")}
          onPressedChange={() => editor?.chain().focus().toggleStrike().run()}
          className="p-2 rounded-md hover:bg-gray-200"
        >
          <Strikethrough size={24} />
        </Toggle>
        <Toggle
          size="sm"
          pressed={editor?.isActive("heading")}
          onPressedChange={() =>
            editor?.chain().focus().toggleHeading({ level: 2 }).run()
          }
          className="p-2 rounded-md hover:bg-gray-200"
        >
          <Heading2 size={24} />
        </Toggle>
        <Toggle
          size="sm"
          pressed={editor?.isActive("heading")}
          onPressedChange={() =>
            editor?.chain().focus().toggleHeading({ level: 3 }).run()
          }
          className="p-2 rounded-md hover:bg-gray-200"
        >
          <Heading3 size={24} />
        </Toggle>
        <Toggle
          size="sm"
          pressed={editor?.isActive("bulletList")}
          onPressedChange={() =>
            editor?.chain().focus().toggleBulletList().run()
          }
          className="p-2 rounded-md hover:bg-gray-200"
        >
          <List size={24} />
        </Toggle>
        <Toggle
          size="sm"
          pressed={editor?.isActive("orderedList")}
          onPressedChange={() =>
            editor?.chain().focus().toggleOrderedList().run()
          }
          className="p-2 rounded-md hover:bg-gray-200"
        >
          <ListOrdered size={24} />
        </Toggle>
      </div>
    </div>
  );
};

export default Toolbar;
