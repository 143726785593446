import { Routes, Route } from "react-router-dom";
import RequireAuth from "./components/careChampion/RequireAuth.tsx";

import OurStory from "./pages/HeaderPages/OurStory.tsx";
import HowItWorks from "./pages/HeaderPages/HowItWorks.tsx";
import Resources from "./pages/HeaderPages/Resources.tsx";
import BlogPages from "./pages/HeaderPages/Blog.tsx";
import BlogPage from "./pages/Admin/AdminSuperAdmin/Blogs/Blog.tsx";
import PrivacyPolicy from "./pages/LegalPages/PrivacyPolicy.tsx";

import Contact from "./pages/FooterPages/Contact.tsx";
import ForgotPassword from "./components/user/ForgotPassword.tsx";
import ResetPassword from "./components/user/ResetPassword.tsx";
import ResetPasswordSuccess from "./components/user/ResetPasswordSuccess.tsx";
import CreateAccount from "./components/user/CreateAccount.tsx";
import MyAccount from "./pages/Admin/AdminCareChampion/MyAccount.tsx";

import Home from "./pages/Home.tsx";
import SearchResults from "./pages/HeaderPages/SearchResults.tsx";
import Footer from "./components/footer/Footer.tsx";

import Community from "./pages/Community/Community.tsx";
import SeniorAdvisor from "./pages/SeniorAdvisor/Advisors.tsx";
import FAQ from "./components/faq/Faq.tsx";
import AdminLogin from "./pages/Admin/Admin.tsx";
import NotFound from "./pages/Public/NotFound.tsx";
import ManageAdvisors from "./components/advisorList/ManageAdvisors.tsx";
import PersistLogin from "./components/user/PersistLogin.tsx";
import Unauthorized from "./pages/Public/Unauthorized.tsx";
import FindAnAdvisor from "./pages/HeaderPages/FindAnAdvisor.tsx";
import AgentRegisterPage from "./components/advisorAccount/Register.tsx";
import AgentRegister from "./pages/Admin/AdminSeniorAdvisor/Register.tsx";
import LayoutWithSearchBar from "./pages/Public/LayoutWithSearchBar.tsx";
import LayoutWithOutSearchBar from "./pages/Public/LayoutWithoutSearchBar.tsx";
import FreeServices from "./pages/HeaderPages/FreeServices.tsx";
import {
  InitialMeeting,
  ComplimentaryAssessments,
  MoveInAssistance,
  FreeGuidedTours,
} from "./components/utils/stepsForServices/FreeServicesPages.tsx";
import Disclosure from "./pages/LegalPages/Disclosure.tsx";

// import Calendar from "./components/calendar/Calendar.tsx";

import SeniorLivingGuide from "./pages/FooterPages/SeniorLivingGuide.tsx";
import LegalAndFinancial from "./pages/LegalPages/LegalAndFinancialPlanning.tsx";
import TypesOfCare from "./pages/FooterPages/TypesOfCare.tsx";

import { UserProvider } from "./context/UserContext.tsx";
import Admin from "./pages/Admin/Admin.tsx";

// Super Admin
import BlogControl from "./pages/Admin/AdminSuperAdmin/Blogs/BlogControl.tsx";

// Senior Advisor
import AdminSeniorAdvisor from "./pages/Admin/AdminSeniorAdvisor/AdminSeniorAdvisor.tsx";
import Performance from "./pages/Admin/AdminSeniorAdvisor/Performance.tsx";
import Leads from "./pages/Admin/AdminSeniorAdvisor/Leads.tsx";
import SeniorAdvisorCalendar from "./pages/Admin/AdminSeniorAdvisor/Calendar.tsx";
import ClientTransitionSupport from "./pages/Admin/AdminSeniorAdvisor/ClientTransitionSupport.tsx";
import Favorites from "./pages/Admin/AdminSeniorAdvisor/Favorites.tsx";
import TourManagement from "./pages/Admin/AdminSeniorAdvisor/TourManagement.tsx";
// import Dashboard from "./components/admin/SeniorAdvisorDashboard.tsx";
import SeniorAdvisorSettings from "./pages/Admin/AdminSeniorAdvisor/Settings.tsx";
import SeniorLeadCard from "./components/admin/InfoCard/Components/SeniorLeadCard/SeniorLeadCard.tsx";
import SeniorAdvisorDashboard from "./components/admin/SeniorAdvisor/SeniorAdvisorDashboard.tsx";
import SeniorAdvisorLogin from "./pages/Admin/AdminSeniorAdvisor/Login.tsx";
import SeniorAdvisorRegister from "./components/advisorAccount/SeniorAdvisorRegister.tsx";

// community champion
import CommunityChampion from "./pages/Admin/AdminCommunityChampion/AdminCommunityChampion.tsx";
import CommunityChampionProfile from "./components/admin/Community/CommunityChampionProfile.tsx";
import CommunityDashboard from "./components/admin/Community/CommunityDashboard.tsx";
import CommunityChampionLogin from "./pages/Admin/AdminCommunityChampion/Login.tsx";
import CommunityCalendar from "./pages/Admin/AdminCommunityChampion/Calendar/Calendar.tsx";
import ClaimACommunity from "./pages/Community/ClaimCommunity.tsx";
import RegisterCommunity from "./pages/Admin/AdminCommunityChampion/Register.tsx";

// Care Champion
import CareChampion from "./pages/Admin/AdminCareChampion/AdminCareChampion.tsx";
import CareChampionLogin from "./pages/Admin/AdminCareChampion/Login.tsx";
import RegisterCareChampion from "./components/careChampion/Register.tsx";
import CareChampionDashboard from "./components/admin/CareChampion/CareChampionDashboard.tsx";
import CareChampionRegister from "./pages/Admin/AdminCareChampion/Register.tsx";
import CareChampionSettings from "./pages/Admin/AdminCareChampion/Settings.tsx";

import { ToastContainer } from "react-toastify";

const ROLES_LIST = {
  ADMIN: "1",
  CARE_CHAMPION: "1000",
  COMMUNITY_CHAMPION: "2000",
  SENIOR_ADVISOR: "3000",
};

function App() {
  return (
    <>
      <UserProvider>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<LayoutWithSearchBar />}>
            {/* PUblic routes */}
            <Route path="/" element={<Home />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="/search-results" element={<SearchResults />} />
            <Route path="/faq" element={<FAQ />} />
          </Route>
          {/* Pages */}
          <Route path="/" element={<LayoutWithOutSearchBar />}>
            <Route path="/our-story" element={<OurStory />} />
            <Route path="/how-it-works" element={<HowItWorks />} />
            <Route path="/resources" element={<Resources />} />
            <Route path="/blog-pages" element={<BlogPages />} />
            <Route path="blog-pages/:blogId" element={<BlogPage />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/find-an-advisor" element={<FindAnAdvisor />} />
            <Route path="free-services" element={<FreeServices />} />
            <Route path="/community/:dynamicRoute" element={<Community />} />
            <Route path="/disclosure" element={<Disclosure />} />
            <Route path="/claim-a-community" element={<ClaimACommunity />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/senior-advisor/:advisorId"
              element={<SeniorAdvisor />}
            />
            <Route
              path="/senior-living-guide"
              element={<SeniorLivingGuide />}
            />
            <Route
              path="/legal-and-financial"
              element={<LegalAndFinancial />}
            />
            <Route path="/types-of-care" element={<TypesOfCare />} />

            {/* free services */}
            <Route path="/initial-meeting" element={<InitialMeeting />} />
            <Route
              path="/complimentary-assessments"
              element={<ComplimentaryAssessments />}
            />
            <Route path="/move-in-assistance" element={<MoveInAssistance />} />
            <Route path="/free-guided-tours" element={<FreeGuidedTours />} />
          </Route>

          {/* user & admin */}
          <Route path="/super-admin" element={<AdminLogin />} />
          <Route
            path="/super-admin/manage-advisors"
            element={<ManageAdvisors />}
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/reset-password-success"
            element={<ResetPasswordSuccess />}
          />
          <Route path="/create-account" element={<CreateAccount />} />

          {/* for care champions | left drawer */}
          <Route path="/my-account" element={<MyAccount />} />

          {/* Care Champion login & register */}
          <Route
            path="/care-champion-register"
            element={<RegisterCareChampion />}
          />

          {/* Senior Advisor register */}
          <Route
            path="/senior-advisor-register"
            element={<SeniorAdvisorRegister />}
          />

          {/* Senior Advisor Admin */}
          <Route path="/admin" element={<Admin />}>
            <Route path="/admin/blog" element={<BlogControl />} />
            <Route
              path="/admin/senior-advisor"
              element={<AdminSeniorAdvisor />}
            >
              {/* Advisor */}
              <Route
                path="/admin/senior-advisor/registerpage"
                element={<AgentRegisterPage />}
              />
              <Route
                path="/admin/senior-advisor/register"
                element={<AgentRegister />}
              />

              <Route
                path="/admin/senior-advisor/login"
                element={<SeniorAdvisorLogin />}
              />

              <Route
                path="/admin/senior-advisor/dashboard"
                element={<SeniorAdvisorDashboard />}
              />
              <Route
                path="/admin/senior-advisor/leads-card"
                element={<SeniorLeadCard />}
              />

              {/* <Route path="/admin/dashboard" element={<Dashboard />} /> */}
              <Route
                path="/admin/senior-advisor/lead-tracker"
                element={<Leads />}
              />
              <Route
                path="/admin/senior-advisor/performance"
                element={<Performance />}
              />
              <Route
                path="/admin/senior-advisor/calendar"
                element={<SeniorAdvisorCalendar />}
              />
              <Route
                path="/admin/senior-advisor/client-transition-support"
                element={<ClientTransitionSupport />}
              />
              <Route
                path="/admin/senior-advisor/tour-management"
                element={<TourManagement />}
              />
              <Route
                path="/admin/senior-advisor/favorites"
                element={<Favorites />}
              />

              <Route
                path="/admin/senior-advisor/settings"
                element={<SeniorAdvisorSettings />}
              />
            </Route>
            {/* Community Champion Admin */}
            <Route
              path="/admin/community-champion"
              element={<CommunityChampion />}
            >
              <Route
                path="/admin/community-champion/dashboard"
                element={<CommunityDashboard />}
              />
              <Route
                path="/admin/community-champion/login"
                element={<CommunityChampionLogin />}
              />
              <Route
                path="/admin/community-champion/register"
                element={<RegisterCommunity />}
              />
              <Route
                path="/admin/community-champion/profile"
                element={<CommunityChampionProfile />}
              />
              <Route
                path="/admin/community-champion/calendar"
                element={<CommunityCalendar />}
              />
            </Route>
            {/* Care Champion Admin */}
            <Route path="/admin/care-champion" element={<CareChampion />}>
              <Route
                path="/admin/care-champion/login"
                element={<CareChampionLogin />}
              />
              <Route
                path="/admin/care-champion/register"
                element={<CareChampionRegister />}
              />
              <Route
                path="/admin/care-champion/settings"
                element={<CareChampionSettings />}
              />
              <Route
                element={
                  <RequireAuth allowedRoles={[ROLES_LIST.CARE_CHAMPION]} />
                }
              >
                <Route
                  path="/admin/care-champion/dashboard"
                  element={<CareChampionDashboard />}
                />
              </Route>
            </Route>
          </Route>
          {/* Persist Login */}

          {/* Catch-all route for 404 Not Found */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </UserProvider>
      <Footer />
    </>
  );
}

export default App;
