import React, { useState } from "react";
import Pagination from "../utils/Pagination.tsx"; // Import the Pagination component
import EditAdvisorPopup from "./EditAdvisorPopup.tsx"; // Import the EditAdvisorPopup component
import useBodyClass from "../../hooks/useBodyClass.tsx";
import { Advisor, AdvisorListProps } from "../../types/types.ts";

const AdvisorList: React.FC<AdvisorListProps> = ({ advisors }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [advisorsPerPage, setAdvisorsPerPage] = useState(5);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedAdvisor, setSelectedAdvisor] = useState<Advisor | null>(null);

  useBodyClass("popup-active", isPopupOpen);

  // Calculate the indices for the current page
  const indexOfLastAdvisor = currentPage * advisorsPerPage;
  const indexOfFirstAdvisor = indexOfLastAdvisor - advisorsPerPage;
  const currentAdvisors = advisors.slice(
    indexOfFirstAdvisor,
    indexOfLastAdvisor
  );

  // Calculate total pages
  const totalPages = Math.ceil(advisors.length / advisorsPerPage);

  // Handle page change
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // Handle advisors per page change
  const handleAdvisorsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setAdvisorsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page
  };

  // Handle edit button click
  const handleEditClick = (advisor: Advisor) => {
    setSelectedAdvisor(advisor);
    setIsPopupOpen(true);
  };

  // Handle popup close
  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSelectedAdvisor(null);
  };

  // Handle save advisor
  const handleSaveAdvisor = (updatedAdvisor: Advisor) => {
    // Update the advisor in the list (this is a simple example, you might need to update the state or make an API call)
    const updatedAdvisors = advisors.map((advisor) =>
      advisor.name === updatedAdvisor.name ? updatedAdvisor : advisor
    );
    // Update the state with the new list of advisors
    // setAdvisors(updatedAdvisors); // Uncomment this line if you have a state for advisors
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Name</th>
            <th className="py-2 px-4 border-b">Location</th>
            <th className="py-2 px-4 border-b">Areas Served</th>
            <th className="py-2 px-4 border-b">Action</th>
          </tr>
        </thead>
        <tbody>
          {currentAdvisors.map((advisor, index) => (
            <tr key={index}>
              <td className="py-2 px-4 border-b">{advisor.name}</td>
              <td className="py-2 px-4 border-b">{advisor.location}</td>
              <td className="py-2 px-4 border-b">{advisor.areasServed}</td>
              <td className="py-2 px-4 border-b">
                <button
                  onClick={() => handleEditClick(advisor)}
                  className="px-4 py-2 bg-primary text-accent rounded hover:bg-secondary"
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        itemsPerPage={advisorsPerPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleAdvisorsPerPageChange}
      />
      <EditAdvisorPopup
        advisor={selectedAdvisor}
        isOpen={isPopupOpen}
        onClose={handleClosePopup}
        onSave={handleSaveAdvisor}
      />
    </div>
  );
};

export default AdvisorList;
