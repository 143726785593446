// Icons from shadcn or Tailwind-based library
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faHome,
  faHandHoldingHeart,
  faHandsHelping,
} from "@fortawesome/free-solid-svg-icons";
import CircleWArrows from "./CircleWArrows.svg";

const steps = [
  {
    id: 1,
    title: "Reach Out",
    description:
      "We’ll respond to discuss your requirements. Email, phone, or video chat options are available.",
    icon: <FontAwesomeIcon icon={faPhone} className="h-6 w-6 text-gray-700" />,
  },
  {
    id: 2,
    title: "Explore Communities",
    description:
      "We’ll help you find the perfect fit by providing personalized recommendations.",
    icon: (
      <FontAwesomeIcon
        icon={faHandsHelping}
        className="h-6 w-6 text-gray-700"
      />
    ),
  },
  {
    id: 3,
    title: "Access Support Services",
    description:
      "Receive tailored support services to meet your individual needs while transitioning.",
    icon: (
      <FontAwesomeIcon
        icon={faHandHoldingHeart}
        className="h-6 w-6 text-gray-700"
      />
    ),
  },
  {
    id: 4,
    title: "Welcome Home!",
    description:
      "We’ll help you get acclimated to your new community and provide follow-up assistance.",
    icon: <FontAwesomeIcon icon={faHome} className="h-6 w-6 text-gray-700" />,
  },
];

const HowItWorksHome = () => {
  return (
    <section className="bg-gray-100 py-10 px-6">
      <h2 className="text-2xl font-bold text-center mb-8">How It Works</h2>
      <div className="flex flex-col md:flex-row items-center justify-center space-y-6 md:space-y-0 md:space-x-8">
        {/* Circle with Arrows */}
        <div className="relative w-60 h-60">
          <img src={CircleWArrows} alt="" />
        </div>

        {/* Steps */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-4xl">
          {steps.map((step) => (
            <div
              key={step.id}
              className="flex flex-col md:flex-row items-center space-x-4 bg-white shadow-md rounded-md p-4"
            >
              <div className="flex-shrink-0">{step.icon}</div>
              <div>
                <h3 className="text-lg font-semibold">
                  {step.id}. {step.title}
                </h3>
                <p className="text-sm text-gray-600">{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorksHome;
