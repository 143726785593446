import React, { useState } from "react";

interface FormErrors {
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  confirmEmail?: string;
  password?: string;
  confirmPassword?: string;
  country?: string;
  optIn?: string;
}

const PersonalDetails = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    country: "",
  });
  const [errors, setErrors] = useState<FormErrors>({});

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const newErrors: FormErrors = {};

    // First Name Validation
    if (!formData.firstName) {
      newErrors.firstName = "Please enter a value for First Name";
    } else if (!/^[a-zA-Z][a-zA-Z ,.'-]*$/.test(formData.firstName)) {
      newErrors.firstName =
        "First Name must start with a letter and may only contain the letters a-z, spaces, and the following characters: ,.'-";
    }
    // Last Name Validation
    if (!formData.lastName) {
      newErrors.lastName = "Please enter a value for Last Name";
    } else if (!/^[a-zA-Z][a-zA-Z ,.'-]*$/.test(formData.lastName)) {
      newErrors.lastName =
        "Last Name must start with a letter and may only contain the letters a-z, spaces, and the following characters: ,.'-";
    }
    // Phone Validation
    if (!formData.phone) {
      newErrors.phone = "Please enter a value for Phone number";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits";
    }
    // Email Validation
    if (!formData.email) {
      newErrors.email = "Please enter a value for Email";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
    }
    // Country Validation
    if (!formData.country) {
      newErrors.country = "Please select a value for Country";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (validateForm()) {
      // Save the form data
      alert("Saved!");
    }
  };

  return (
    <div className="w-full max-w-2xl p-4 space-y-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-center text-primary">
        My Personal Details
      </h2>
      <form
        className=" grid grid-cols-1 md:grid-cols-2 gap-4"
        onSubmit={handleSubmit}
      >
        <div>
          <label className="block text-sm font-medium text-secondary">
            First Name
          </label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            maxLength={35}
            required
            className="w-full px-3 py-2 mt-1 border rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
          />
          {errors.firstName && (
            <p className="mt-1 text-sm text-error">{errors.firstName}</p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium text-secondary">
            Phone
          </label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            maxLength={10}
            required
            className="w-full px-3 py-2 mt-1 border rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
          />
          {errors.phone && (
            <p className="mt-1 text-sm text-error">{errors.phone}</p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium text-secondary">
            Country
          </label>
          <select
            name="country"
            value={formData.country}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 mt-1 border rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
          >
            <option value="">Select Country</option>
            <option value="USA">USA</option>
            <option value="Canada">Canada</option>
            <option value="UK">UK</option>
          </select>
          {errors.country && (
            <p className="mt-1 text-sm text-error">{errors.country}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-secondary">
            Last Name
          </label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            maxLength={35}
            required
            className="w-full px-3 py-2 mt-1 border rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
          />
          {errors.lastName && (
            <p className="mt-1 text-sm text-error">{errors.lastName}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-secondary">
            Email
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            maxLength={255}
            required
            className="w-full px-3 py-2 mt-1 border rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
          />
          {errors.email && (
            <p className="mt-1 text-sm text-error">{errors.email}</p>
          )}
        </div>

        <button
          type="submit"
          className="w-full px-4 py-2 font-bold text-accent bg-primary rounded-md hover:bg-secondary focus:outline-none focus:ring focus:ring-indigo-200"
        >
          Save
        </button>
      </form>
    </div>
  );
};

export default PersonalDetails;
