interface PaginationProps {
  currentPage: number;
  totalItems: number;
  onPrevious: () => void;
  onNext: () => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalItems,
  onPrevious,
  onNext,
}) => {
  return (
    <div className="flex items-center justify-between mt-4">
      <button
        onClick={onPrevious}
        className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300 hover:cursor-pointer disabled:opacity-50"
        disabled={currentPage === 0}
      >
        &larr;
      </button>
      <span>
        Lead {currentPage + 1} of {totalItems}
      </span>
      <button
        onClick={onNext}
        className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300 hover:cursor-pointer disabled:opacity-50"
        disabled={currentPage === totalItems - 1}
      >
        &rarr;
      </button>
    </div>
  );
};

export default Pagination;
