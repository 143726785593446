import Profile from "./CommunityChampionProfile.tsx";
import LeadTracker from "../InfoCard/Components/Lead/LeadTracker.tsx";

const CommunityDashboard: React.FC = () => {
  return (
    <>
      <div className="flex items-center mb-4">
        <h1 className="text-2xl font-bold">Community Champion</h1>
      </div>
      <div className="flex space-x-4 justify-between">
        <Profile />
        <LeadTracker />
      </div>
    </>
  );
};

export default CommunityDashboard;
