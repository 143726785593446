import axiosInstance from "../../api/axiosInstance";
import { useEffect, useState } from "react";
// import BlogCard from "./BlogCard";
import BlogGrid from "./BlogGrid";
interface BlogPost {
  id: number;
  title: string;
  body: string;
  publishedOn: string;
  visible: boolean;
  author: string;
  imgUrl?: string;
}

const BlogPostDisplay: React.FC = () => {
  const [posts, setPosts] = useState<BlogPost[]>([]);

  useEffect(() => {
    const fetchAndSortPosts = async () => {
      try {
        const response = await axiosInstance.get("/blogs");

        if (response.data.length === 0) return;

        const sortedPosts = response.data
          .map((post: BlogPost) => ({
            ...post,
            publishedOn: new Date(post.publishedOn).toLocaleDateString(
              "en-US",
              {
                year: "numeric",
                month: "long",
                day: "numeric",
              }
            ),
          }))
          .sort((a: BlogPost, b: BlogPost) => {
            const dateA = new Date(a.publishedOn).getTime();
            const dateB = new Date(b.publishedOn).getTime();
            return dateB - dateA; // Most recent first
          });

        setPosts(sortedPosts);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchAndSortPosts();
  }, []);

  return (
    <div className="container my-8">
      <BlogGrid posts={posts} />
      {/* {posts.map((post) => (
        <div
          className="max-h-[450px] bg-white p-6 rounded-lg shadow-lg max-w-3xl mx-auto my-4"
          key={post.id}
        >
          <h1 className="text-2xl font-bold mb-2">{post.title}</h1>
          <div className="text-gray-500 text-sm mb-4">
            By {post.author} • {post.publishedOn}
          </div>
          {post.visible ? (
            <BlogCard
              blogPost={post}
              onClick={() => {
                alert(":clicked");
              }}
            />
          ) : (
            <p className="text-gray-400 italic">
              This post is not visible to the public.
            </p>
          )}
        </div>
      ))} */}
    </div>
  );
};

export default BlogPostDisplay;
