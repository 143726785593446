// import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../context/UserContext.tsx";
import AdminHeader from "../../components/header/AdminHeader.tsx";
import DrawerContainer from "../../components/admin/DrawerContainer.tsx";
import { menuConfig, UserRole } from "./AdminMenuItems.tsx";
import { Outlet } from "react-router-dom";

// Define the menu configuration structure

const Dashboard: React.FC = () => {
  const { userRole } = useUserContext(); // Assume userRole is provided by UserContext

  // Check if userRole is available; render loading state if not
  if (!userRole) {
    return <div>Loading...</div>; // Or a proper loading spinner
  }

  // Access menu based on userRole, ensure valid role is used
  const { topBar, verticalBar } = menuConfig[userRole as UserRole];

  return (
    <>
      <AdminHeader menuItems={topBar} />
      <DrawerContainer items={verticalBar}>
        <Outlet />
      </DrawerContainer>
    </>
  );
};

export default Dashboard;
