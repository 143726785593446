import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface RequireAuthProps {
  allowedRoles: string[];
}

const RequireAuth: React.FC<RequireAuthProps> = ({ allowedRoles }) => {
  const { auth } = useAuth();
  const location = useLocation();

  if (!auth?.roles?.find((role) => allowedRoles?.includes(role))) {
    if (!auth?.user) {
      toast.warn("Please log in to access this page!");
    } else {
      toast.warn("You are not authorized to access this page!");
    }
    return auth?.user ? (
      <Navigate to="/unauthorized" replace />
    ) : (
      <Navigate
        to="/admin/care-champion/login"
        state={{ from: location }}
        replace
      />
    );
  }

  return <Outlet />;
};

export default RequireAuth;
