// AdvisorsGrid.js
import React, { useEffect, useState } from "react";
import SingleAdvisor from "./SingleAdvisor";
import GridAdvisors from "./MultipleAdvisors";
// import { advisorData } from "./AdvisorData";
import axiosInstance from "../api/axiosInstance";

interface Advisor {
  id: number;
  fname: string;
  lname: string;
  dreNo: number;
  email: string;
  mobile: string;
  office: string;
  officeName: string;
  address: string;
  city: string;
  state: string;
  zip: number;
  totalNo: number;
  totalSale: number;
  projTitleTotalFee: number;
  lsNo: number | null;
  lsSale: number;
  projTitleLsFee: number;
  bsNo: number | null;
  bsSale: number;
  projTitleBsFee: number;
  ddNo: number;
  ddSale: number;
  projTitleDdFee: number | null;
}

interface AdvisorsGridProps {
  initialShowSingleAdvisor: boolean;
}

const AdvisorsGrid: React.FC<AdvisorsGridProps> = ({
  initialShowSingleAdvisor,
}) => {
  const [advisorData, setAdvisorData] = useState<Advisor[]>([]);
  useEffect(() => {
    axiosInstance
      .get("/Agents")
      .then((response) => {
        console.log("advisor data", response.data);
        setAdvisorData(response.data);
      })
      .catch((error) => {
        console.log("Error fetching advisor data", error);
      });
  }, []);

  // State to control showing more advisors and current advisor
  const [showMore, setShowMore] = useState(false);
  const [showSingleAdvisor, setShowSingleAdvisor] = useState(
    initialShowSingleAdvisor
  );
  const [currentAdvisorIndex, setCurrentAdvisorIndex] = useState(0);

  // Split headshots based on 'showMore' state
  const visibleHeadshots = showMore ? advisorData : advisorData.slice(0, 12);

  // Show one advisor based on current index
  const currentAdvisor = advisorData[currentAdvisorIndex];

  // Handle previous and next for single advisor
  const handlePrevious = () => {
    setCurrentAdvisorIndex(
      (prevIndex) => (prevIndex - 1 + advisorData.length) % advisorData.length
    );
  };

  const handleNext = () => {
    setCurrentAdvisorIndex((prevIndex) => (prevIndex + 1) % advisorData.length);
  };

  return (
    <div className="container flex  flex-col mx-auto items-center lg:items-start">
      <h2 className="text-3xl font-bold my-6">Our Advisors</h2>

      {/* Toggle View Buttons */}
      {/* <div className="text-center mb-6">
        <button
          onClick={() => setShowSingleAdvisor(!showSingleAdvisor)}
          className="px-6 py-3 bg-primary text-white rounded-lg font-semibold hover:bg-secondary transition duration-300 mr-4"
        >
          {showSingleAdvisor ? "View Grid" : "View One Advisor"}
        </button>
      </div> */}

      {/* Show one advisor or grid */}
      {showSingleAdvisor ? (
        <SingleAdvisor
          advisor={currentAdvisor}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
        />
      ) : (
        <GridAdvisors
          advisors={visibleHeadshots}
          showMore={showMore}
          toggleShowMore={() => setShowMore(!showMore)}
        />
      )}
    </div>
  );
};

export default AdvisorsGrid;
